import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import api from '@shared/services/api.js';
import { createQueryString } from '@shared/utils';

export async function getUserDetails() {
  try {
    const data = await api.get('/sell/api/user_information');
    return data?.user_info;
  } catch (error) {
    onErrorHandler(error, 'core-getUserDetails');
  }
}

export function getPermissions() {
  return api.get('/company/api/permissions/').catch(error => {
    onErrorHandler(error, 'core-getPermissions');
  });
}

export async function getPartners(forSeller = null) {
  try {
    const data = await api.get(`/api/partners?for_seller=${forSeller}`);
    return data.partners || [];
  } catch (error) {
    onErrorHandler(error, 'core-getPartners');
  }
}

export async function getAgencies() {
  try {
    const data = await api.get('/api/agencies');
    return data.agencies || [];
  } catch (error) {
    onErrorHandler(error, 'core-getAgencies');
  }
}

export async function getServicingProviders() {
  try {
    const data = await api.get('/api/servicing_providers');
    return data.servicing_providers || [];
  } catch (error) {
    onErrorHandler(error, 'core-getServicingProviders');
  }
}

export async function hasValidExternalAccount(externalSystemName) {
  try {
    const params = { external_system: externalSystemName };
    const endpoint = createQueryString('/api/external_system_account', params);
    return api.get(endpoint);
  } catch (error) {
    onErrorHandler(error, 'core-hasValidExternalAccount');
  }
}

export async function getAgencyProducts() {
  try {
    const data = await api.get('/buy/api/agency_products');
    return data || {};
  } catch (error) {
    onErrorHandler(error, 'core-getAgencyProducts');
  }
}

export async function getAsyncTask({ taskId, isGroup }, options = null) {
  try {
    const url = api.constructUrl(`/api/async_tasks/${taskId}`, {
      is_group: isGroup ? 'True' : '',
    });
    return await api.get(url, options);
  } catch (error) {
    onErrorHandler(error, 'core-get-async-task', [403], true);
    return {
      task_ready: true,
      task_successful: false,
    };
  }
}

export async function getOperations(
  { operationStatus, operationType },
  options = null,
) {
  try {
    const url = api.constructUrl('/api/operations', {
      operation_status: operationStatus,
      operation_type: operationType || '',
    });
    return await api.get(url, options);
  } catch (error) {
    onErrorHandler(error, 'core-get-operation', [403], true);
  }
}

export const getOrganization = async organizationId => {
  const url = api.constructUrl(`/api/v1/organizations/${organizationId}`);
  return api.get(url);
};

export const getOrganizations = async () => {
  return api.get('/api/v1/organizations/');
};

export const getLoanPrograms = async () => {
  return api.get('/api/v1/loan_programs/');
};

export const sendNLIMessage = async data => {
  return api.post('/api/v1/nli/', data);
};

export const getProductTypes = async tickerSymbol => {
  const queryParams = new URLSearchParams({
    organization__organizationinfo__ticker_symbol: tickerSymbol,
  });
  return api.get(`/api/v1/product_types/?${queryParams}`);
};

export const getLoanSubtypes = async () => {
  return api.get('/api/v1/loan_subtypes/');
};

export const getUserProfile = async () => {
  return api.get('/api/profile/');
};
