import api from '@shared/services/api.js';

function buildUrl(orgId, queryData = null) {
  const baseUrl = `/api/v1/organizations/${orgId}/rate_alerts/`;

  if (queryData) {
    const queryParams = new URLSearchParams(queryData);
    return `${baseUrl}?${queryParams}`;
  }

  return baseUrl;
}

export const fetchRateAlerts = async (organizationId, queryData) => {
  return api.get(buildUrl(organizationId, queryData));
};

export const fetchRateAlertById = async (organizationId, rateAlertId) => {
  return api.get(`${buildUrl(organizationId)}${rateAlertId}/`);
};

export const createRateAlert = async (organizationId, data) => {
  return api.postJson(buildUrl(organizationId), data);
};

export const updateRateAlert = async (organizationId, data) => {
  return api.putJson(`${buildUrl(organizationId)}${data.id}/`, data);
};

export const deleteRateAlert = async (organizationId, rateAlertId) => {
  return api.delete(`${buildUrl(organizationId)}${rateAlertId}/`);
};

export const getOrganizationRateAlertSchedules = async organizationId => {
  return api.get(
    `/api/v1/organizations/${organizationId}/rate_alert_schedules/`,
  );
};

export const createRateAlertSchedule = async (organizationId, data) => {
  return api.postJson(
    `/api/v1/organizations/${organizationId}/rate_alert_schedules/`,
    data,
  );
};

export const updateRateAlertSchedule = async (organizationId, data) => {
  return api.putJson(
    `/api/v1/organizations/${organizationId}/rate_alert_schedules/${data.id}/`,
    data,
  );
};
