export const CUMUL = 'cumul';
export const NLI_ASSISTANT = 'nli_assistant';
export const AVERAGE_PRIME_OFFER_RATE = 'average_prime_offer_rate';

export const PRICER_UI_REDESIGN_FRONTEND = 'pricer_ui_redesign_frontend';
export const PRICER_UI_QA_IS_MOBILE = 'pricer_ui_qa_is_mobile';

export const EXPIRE_SUSPEND_PRICING = 'expire_suspend_pricing';

export const ENABLE_PRODUCT_CREATION_BUTTON = 'enable_product_creation_button';

export const SET_SOURCE_RATE_SHEET_UPLOAD_INTERNAL =
  'set_source_rate_sheet_upload_internal_use';

export const NEW_SELL_SIDE_FIELDS = 'new_sell_side_fields_app_13014';

export const TOTAL_LTV = 'enable_total_ltv_on_pricer_ui';

export const GRID_RETAIN_VALUES = 'grids_retain_values';

export const ENABLE_COMPLIANCE_RULES_UI = 'enable_compliance_rules_ui';

export const USES_LOAN_SUB_TYPES = 'uses_loan_sub_types';

export const LOAN_ID_IN_PIPELINE_VIEWS = 'display_loan_id_in_pipeline_views';
