<template>
  <form class="chat-widget__form" @submit.prevent="submitForm">
    <button
      class="chat-widget__mic-button"
      :disabled="isSending"
      type="button"
      @click="toggleAudioCapture"
    >
      <i class="fa fa-microphone"></i>
      <div v-show="isRecording" class="chat-widget__recording-indicator"></div>
    </button>
    <textarea
      ref="textarea"
      v-model="newMessageText"
      class="chat-widget__input"
      placeholder="Type your message here..."
      @input="adjustTextareaHeight"
      @keydown="handleKeyDown"
    ></textarea>
    <button class="chat-widget__button" type="submit">
      <i v-if="!isSending" alt="" class="fa fa-paper-plane"></i>
      <img v-else alt="" class="stop-square" :src="StopButton" />
    </button>
  </form>
</template>

<script>
import { COPILOT_GETTERS } from '@src/shared/store/modules/copilot';
import StopButton from '@shared/assets/images/stop.svg';
import { mapGetters } from 'vuex';
import { onErrorHandler } from '@src/shared/utils/errorHandlers';

export default {
  name: 'ChatInput',
  data() {
    return {
      newMessageText: '',
      isRecording: false,
      mediaRecorder: null,
      recordingTimeoutId: null,
      StopButton: StopButton,
    };
  },
  computed: {
    ...mapGetters({
      isSending: `copilot/${COPILOT_GETTERS.isSending}`,
    }),
  },
  methods: {
    adjustTextareaHeight() {
      const textarea = this.$refs.textarea;
      textarea.style.height = 'auto';
      const height = Math.min(textarea.scrollHeight, 100);
      if (height > textarea.clientHeight && height >= 50) {
        textarea.style.height = `${height}px`;
      } else {
        textarea.style.height = '50px';
      }

      if (this.newMessageText === '') {
        textarea.style.height = '50px';
      }
    },
    handleKeyDown(e) {
      const enterKey = 13;
      if (e.keyCode === enterKey && !e.shiftKey) {
        e.preventDefault();

        if (!this.isSending) {
          this.submitForm();
        }
      }
    },
    submitForm() {
      if (this.isSending) {
        // This means that the user clicked the stop button and we should abort active requests
        this.$nliService.handleStopCopilotResponse();
        return;
      }
      this.$nliService.sendMessage(this.newMessageText);
      this.newMessageText = '';
      this.adjustTextareaHeight();
    },
    transcribeAudio(audioBase64) {
      this.$nliService.sendAudioMessage(audioBase64); // Send base64 audio for transcription
    },
    toggleAudioCapture() {
      if (!this.isRecording) {
        this.startAudioCapture(); // If not recording, start the capture
      } else {
        this.stopAudioCapture(); // If already recording, stop the capture
      }
    },
    stopAudioCapture() {
      // Logic to stop audio capture and clean up
      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach(track => track.stop()); // Stop the media stream
      }
      if (this.recorder) {
        this.recorder.disconnect();
      }

      this.createWAVBlob(this.audioChunks, this.audioContext.sampleRate)
        .then(audioBlob => this.convertBlobToBase64(audioBlob))
        .then(audioBase64 => this.transcribeAudio(audioBase64)); // Send the base64-encoded audio
      this.cleanupRecording();
    },
    startAudioCapture() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(stream => {
            this.mediaStream = stream;
            this.audioContext = new (window.AudioContext ||
              window.webkitAudioContext)();
            this.recorder = this.audioContext.createScriptProcessor(4096, 1, 1);

            const input = this.audioContext.createMediaStreamSource(stream);
            input.connect(this.recorder);
            this.recorder.connect(this.audioContext.destination);

            this.audioChunks = []; // Array to store audio chunks
            this.isRecording = true; // Set recording state to true

            // On audio process, store the audio buffer
            this.recorder.onaudioprocess = e => {
              const inputData = e.inputBuffer.getChannelData(0);
              this.audioChunks.push(new Float32Array(inputData)); // Store the audio chunks
            };

            // Automatically stop recording after a set time or user action
            this.recordingTimeoutId = setTimeout(
              () => this.stopAudioCapture(),
              60000,
            );
          })
          .catch(error => {
            this.cleanupRecording();
            onErrorHandler(
              `Error accessing the microphone: ${error.message}`,
              'Audio-Error',
              [],
              true,
            );
          });
      } else {
        onErrorHandler(
          'Browser does not support getUserMedia API',
          'Audio-Error',
          [],
          true,
        );
      }
    },
    cleanupRecording() {
      this.isRecording = false;
      if (this.recordingTimeoutId) {
        clearTimeout(this.recordingTimeoutId);
        this.recordingTimeoutId = null;
      }
    },
    createWAVBlob(audioChunks, sampleRate) {
      return new Promise(resolve => {
        const bufferLength = audioChunks.reduce(
          (sum, chunk) => sum + chunk.length,
          0,
        );
        const buffer = new Float32Array(bufferLength);

        let offset = 0;
        for (const chunk of audioChunks) {
          buffer.set(chunk, offset);
          offset += chunk.length;
        }

        const wavBuffer = this.encodeWAV(buffer, sampleRate); // Encode to WAV format
        const audioBlob = new Blob([wavBuffer], { type: 'audio/wav' });
        resolve(audioBlob);
      });
    },
    encodeWAV(samples, sampleRate) {
      // Initialize the buffer and data view for creating the WAV file
      const buffer = new ArrayBuffer(44 + samples.length * 2); // 44-byte WAV header + samples
      const view = new DataView(buffer);

      // Write the WAV header
      this.writeString(view, 0, 'RIFF');
      view.setUint32(4, 36 + samples.length * 2, true); // File Size
      this.writeString(view, 8, 'WAVE'); // File Type
      this.writeString(view, 12, 'fmt '); // Format Chunk Marker
      view.setUint32(16, 16, true); // Subchunk Size for PCM
      view.setUint16(20, 1, true); // Audio Format (PCM)
      view.setUint16(22, 1, true); // Mono (Channels)
      view.setUint32(24, sampleRate, true); // Sample Rate
      view.setUint32(28, sampleRate * 2, true); // Byte Rate
      view.setUint16(32, 2, true); // Block Align
      view.setUint16(34, 16, true); // Bits Per Sample
      this.writeString(view, 36, 'data'); // Data Chunk Marker
      view.setUint32(40, samples.length * 2, true); // Subchunk Size

      // Write the PCM samples from recording to the buffer
      let offset = 44;
      for (let i = 0; i < samples.length; i++, offset += 2) {
        const s = Math.max(-1, Math.min(1, samples[i]));
        view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
      }

      return buffer;
    },
    writeString(view, offset, string) {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    },
    convertBlobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob); // Read the blob as a Data URL
        reader.onloadend = () => {
          const base64data = reader.result.split(',')[1]; // Extract base64 string
          resolve(base64data); // Resolve promise with base64 audio
        };
        reader.onerror = error => reject(error); // Handle any errors during conversion
      });
    },
  },
};
</script>

<style lang="scss">
@import '@shared/styles/variables.scss';

.chat-widget__form {
  /* remove absolute positioning */
  display: flex;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
}

.chat-widget__input {
  flex: 1;
  padding: 15px;
  border: 1px solid #ccc;
  border-left: transparent;
  outline: none;
  resize: none;
  overflow: hidden;
  word-wrap: break-word;
  height: 50px;
  max-height: 100px;
  overflow-y: auto;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.chat-widget__input:focus {
  border-top: 2px solid $polly-light-blue;
}

.chat-widget__button {
  padding: 5px 20px;
  background-color: $polly-light-blue;
  color: #fff;
  border: none;
  cursor: pointer;
}

.chat-message-box {
  display: flex;
  align-items: flex-start;
}

.chat-widget__mic-button {
  position: relative; /* Ensure the element's position is set to allow absolute children positioning */
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: transparent;
  border-right: transparent;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 10px;
  color: #48aff0; /* Polly light blue color for the icon */
  &:hover {
    background-color: #e1f3fb; /* Light blue background on hover */
  }
}

.fa-microphone {
  color: #48aff0; /* Polly light blue color for the icon */
}

.chat-widget__mic-button:disabled {
  opacity: 0.5;
}

.stop-square {
  width: 13px;
  height: 13px;
  background-color: white;
  border-radius: 2px;
}

.chat-widget__recording-indicator {
  /* Modify the style to represent a top border instead of a dot */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px; /* Adjust the height to represent a top line instead of a dot */
  background: red; /* Set the color for the recording indicator to red */
  display: block;
  /* Reuse the blink-animation keyframes defined in the style */
  animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
</style>
