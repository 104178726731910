import api from '@shared/services/api.js';
import axios from 'axios';
import { camelCaseToTitleCase } from '@src/shared/utils/stringFormatters';
import ChatBus from '@shared/components/Chat/ChatBus.js';
import * as SemanticMatchService from '@shared/components/Chat/services/SemanticMatchService.js';
import { onErrorHandler } from '@src/shared/utils/errorHandlers';
import { getPricingScenarios } from '@pe/services/pricingScenarios';
import { saveScenario } from '@pe/components/Pricing/pricingUtils';
import clone from 'lodash/cloneDeep';
import { filterObject } from '@src/shared/utils/helpers';
import {
  generateLoanTranslationSchema,
  generateBorrowerTranslationSchema,
  generatePropertyTranslationSchema,
  generateSearchTranslationSchema,
  extractOptions,
  filterSchema,
} from '@shared/components/Chat/TranslationUtils.js';
import {
  generateResponse,
  loanTranslationSentiments,
  pricingRetrievalSentiments,
  genericTaskCompleteSentiments,
  followUps,
  apologies,
  pleasantryList,
  formUpdateFailureList,
  tryAgainList,
  fieldIssuesList,
  affirmatives,
  loadedScenarioFailureSentiments,
  ineligibleProductFailureSentiments,
  loadedScenarioSentiments,
  savedScenarioFailureSentiments,
  savedScenarioSentiments,
  celebrations,
  nearMissSuggestedActions,
  explainPricingSuggestedActions,
  getPricingSuggestedActions,
} from '@shared/components/Chat/responseChunks.js';
import {
  UPDATE_CURRENT_MESSAGE,
  ADD_MESSAGE,
  ADD_MESSAGE_TO_HISTORY,
  STOP_AUDIO,
  PLAY_AUDIO,
  ADD_ACTION_TO_CURRENT_MESSAGE,
  SET_LOAN_TRANSLATION_SCHEMA,
  SET_BORROWER_TRANSLATION_SCHEMA,
  SET_PROPERTY_TRANSLATION_SCHEMA,
  SET_SEARCH_TRANSLATION_SCHEMA,
  SET_TEMPLATE,
  SET_CURRENT_PRICING_PARAMETERS,
  SET_PRICING_REQUESTED,
  SET_BEST_PRICES,
  SET_ELIGIBLE_PRODUCTS,
  SET_INELIGIBLE_PRODUCTS,
  SET_PRICING_SCENARIO,
  SET_CUSTOM_PARAMETERS,
  COPILOT_GETTERS,
  SET_LATEST_TRAFFIC_COP_CLASSIFICATION,
  SET_SUGGESTED_ACTIONS,
  REMOVE_ACTIVE_REQUEST_CONTROLLER,
  ADD_ACTIVE_REQUEST_CONTROLLER,
  CLEAR_ACTIVE_REQUEST_CONTROLLERS,
  COPILOT_ACTIONS,
  SET_OPTION_MAPS,
} from '../store/modules/copilot';
import { NLI_ASSISTANT } from '../constants/flags';
const baseApi = '/nli';
const loanScenarioPaths = [
  '/pe/loan-scenario',
  '^/pe/loans/.*$',
  '^/pe/loans-embed.*$',
];
export const messageSources = {
  CHAT: 'chat',
  VOICE: 'voice',
  INELIGIBILITYEXPAND: 'ineligibility-expand',
};

export const trafficCop = async (body, options = {}) => {
  return api.post(`${baseApi}/traffic-cop`, body, options);
};

export const translateJson = async (body, options = {}) => {
  return api.post(`${baseApi}/translation`, body, options);
};

export const translateBooleanJson = async (body, options = {}) => {
  return api.post(`${baseApi}/translation-boolean`, body, options);
};

export const contextualize = async (body, options = {}) => {
  return api.post(`${baseApi}/contextualizer`, body, options);
};

export const normalizeAndTag = async (body, options = {}) => {
  return api.post(`${baseApi}/normalization-tagger`, body, options);
};

export const selectColumns = async (body, options = {}) => {
  return api.post(`${baseApi}/column-selector`, body, options);
};

export const explainRates = async (body, options = {}) => {
  return api.post(`${baseApi}/rate-explainer`, body, options);
};

export const semanticMatch = async (body, options = {}) => {
  return api.post(`${baseApi}/semantic-match`, body, options);
};

export const mortgageInfo = async (body, options = {}) => {
  return api.post(`${baseApi}/mortgage-info`, body, options);
};

export const optionSelection = async (body, options = {}) => {
  return api.post(`${baseApi}/option-selection`, body, options);
};

export const nameExtraction = async (body, options = {}) => {
  return api.post(`${baseApi}/name-extraction`, body, options);
};

export const scenarioAnalysis = async (body, options = {}) => {
  return api.post(`/pe/api/scenario-analysis/`, body, options);
};

export const nearMiss = async (body, options = {}) => {
  return api.post(`/pe/api/near-miss/`, body, options);
};

export const explainLoanIneligibility = async (body, options = {}) => {
  return api.post(`${baseApi}/explain-ineligibility`, body, options);
};

export const explainNearMiss = async (body, options = {}) => {
  return api.post(`${baseApi}/explain-near-miss`, body, options);
};

export const summarize = async (body, options = {}) => {
  return api.post(`${baseApi}/summarization`, body, options);
};

export const trackMessage = async (body, options = {}) => {
  return api.post(`${baseApi}/messages`, body, options);
};

export const trackMessageAction = async (messageId, body, options = {}) => {
  return api.patch(
    `${baseApi}/messages/${messageId}/add-action/`,
    body,
    options,
  );
};

export const transcribeAudio = async audioBase64 => {
  return api.post(
    '/nli/audio-transcription',
    {
      audio: {
        data: audioBase64,
      },
      model: 'whisper-1',
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export const updateMessage = async (messageId, body, options = {}) => {
  return api.patch(`/nli/messages/${messageId}/`, body, options);
};

const assistantTaskInfo = {
  'Update A Loan Scenario': {
    requirements: [
      'Loan Scenario Information.',
      'Must be on loan scenario page.',
    ],
    howToUse: ["Tell me about the loan scenario, I'll fill it out for you."],
  },
  'Save/Load A Loan Scenario': {
    requirements: ['Name of loan scenario.'],
    howToUse: ['Ask me to save or load a scenario and give me a name.'],
  },
  'Get Pricing': {
    requirements: [],
    howToUse: ["Simply ask me to get pricing, I'll take care of the rest."],
  },
  'Recommend Pricing Options': {
    requirements: [],
    howToUse: [
      "Ask me for pricing recommendations based on your needs, and I'll point out notable rates.",
    ],
  },
  'Explain Product Ineligibility': {
    requirements: ['Name of Product'],
    howToUse: [
      "Ask me about an ineligible product, I'll explain why it's not eligible.",
    ],
  },
  'Answer Mortgage Questions': {
    requirements: [],
    howToUse: ["Ask me a mortgage question, I'll do my best to answer it."],
  },
};

export class NliService {
  constructor(store, vueRouter, nextTick) {
    this.store = store;
    this.vueRouter = vueRouter;
    this.nextTick = nextTick;
  }

  copilot(getter) {
    return this.store.getters[`copilot/${getter}`];
  }

  isNLIAssistantActive() {
    return this.store.getters['core/isFlagActive'](NLI_ASSISTANT);
  }

  isSaveLoadScenarioAvailable() {
    return !this.store.getters['core/canAccessPriceUIOnly'];
  }

  async sendMessage(newMessageText, source = messageSources.CHAT) {
    try {
      this.store.commit(`copilot/${SET_SUGGESTED_ACTIONS}`, []);
      this.processNewMessage(newMessageText, source);

      await this.executeNliAction('traffic-cop', async options =>
        trafficCop(
          {
            prompt: newMessageText,
            history: this.historyToString(
              this.copilot(COPILOT_GETTERS.trafficCopHistoryLength),
            ),
          },
          options,
        ),
      ).then(response => {
        const trafficCopClassification = response.data.result;
        this.routeMessage(trafficCopClassification, response.data.prompt);
        this.store.commit(
          `copilot/${SET_LATEST_TRAFFIC_COP_CLASSIFICATION}`,
          trafficCopClassification,
        );
      });
    } catch (error) {
      this.handleNliError(error, 'traffic-cop');
    }
  }

  getRandomSuggestedAction(suggestedActionList) {
    return suggestedActionList[
      Math.floor(Math.random() * suggestedActionList.length)
    ];
  }

  determineSuggestedActions(currentPath = this.vueRouter.currentRoute.path) {
    if (!this.isNLIAssistantActive()) {
      return;
    }

    if (!this.pageMatch(loanScenarioPaths, currentPath)) {
      this.store.commit(`copilot/${SET_SUGGESTED_ACTIONS}`, []);
      return;
    }

    const classification = this.copilot(
      COPILOT_GETTERS.latestTrafficCopClassification,
    );

    let suggestedActions = [];

    // Default suggested actions on first interaction
    if (this.copilot(COPILOT_GETTERS.allMessages)?.length == 1) {
      suggestedActions = suggestedActions.concat([
        { category: 'general', text: 'How do we update the scenario?' },
        { category: 'general', text: 'Give me the Copilot tutorial' },
        { category: 'general', text: 'Load a scenario' },
      ]);
    }
    if (this.copilot(COPILOT_GETTERS.ineligibleProducts)?.length) {
      const ineligibleProducts = this.copilot(
        COPILOT_GETTERS.ineligibleProducts,
      );
      const randomIndex = Math.floor(Math.random() * ineligibleProducts.length);
      const randomIneligibleProduct = ineligibleProducts[randomIndex];
      suggestedActions = suggestedActions.concat([
        this.getRandomSuggestedAction(nearMissSuggestedActions),
        {
          category: 'ineligibility',
          text: `Why is the ${randomIneligibleProduct.name} ineligible?`,
        },
      ]);
    }
    if (this.copilot(COPILOT_GETTERS.eligibleProducts)?.length) {
      suggestedActions.push(
        this.getRandomSuggestedAction(explainPricingSuggestedActions),
      );
    }
    if (classification === 'json-translation') {
      suggestedActions.push(
        this.getRandomSuggestedAction(getPricingSuggestedActions),
      );
    }

    // Filter out used suggested actions
    const usedSuggestedActionCategories = this.copilot(
      COPILOT_GETTERS.usedSuggestedActionCategories,
    );
    suggestedActions = suggestedActions.filter(
      action => !usedSuggestedActionCategories.includes(action?.category),
    );

    this.store.commit(`copilot/${SET_SUGGESTED_ACTIONS}`, suggestedActions);
    this.scrollToBottom();
  }

  processNewMessage(newMessageText, source = messageSources.CHAT) {
    this.store.dispatch(`copilot/${COPILOT_ACTIONS.setIsSending}`, true);
    this.store.commit(`copilot/${UPDATE_CURRENT_MESSAGE}`, {
      user_message: newMessageText,
      source: source,
    });
    this.addMessage({
      user: true,
      text: newMessageText,
    });
  }

  scrollToBottom() {
    this.nextTick(() => {
      // Get chat messages element
      const chatMessages = document.querySelector('.chat-widget__messages');

      if (!chatMessages) {
        return;
      }

      // Scroll chat to the bottom
      chatMessages.scrollTop = chatMessages.scrollHeight;
    });
  }

  async routeMessage(action, message) {
    try {
      let response = null;

      // Avoid attempting save/load scenario if the user is not allowed to
      if (
        !this.isSaveLoadScenarioAvailable() &&
        ['load-scenario', 'save-scenario'].includes(action)
      ) {
        return this.handleCopilotResponse(
          await this.mortgageInfo(message, [
            'You must explain to the user that you cant currently manage scenarios.',
          ]),
        );
      }

      switch (action) {
        case 'json-translation':
          response = await this.translateLoanScenario(message);
          break;
        case 'mortgage-question':
          response = await this.mortgageInfo(message, [
            'If the question does not relate to the mortgage industry, politely redirect the conversation.',
          ]);
          break;
        case 'get-pricing':
          response = this.getPricing();
          break;
        case 'explain-pricing':
          response = await this.searchRateStack(message);
          break;
        case 'load-scenario':
          response = await this.loadLoanScenario(message);
          break;
        case 'save-scenario':
          response = await this.saveLoanScenario(message);
          break;
        case 'explain-ineligibility':
          response = await this.explainIneligibility(message);
          break;
        case 'explain-near-miss':
          response = await this.explainNearMiss(message);
          break;

        default:
          response = await this.mortgageInfo(
            message,
            [
              'If the question does not relate to the mortgage industry, helpful information, or your abilities, politely redirect the conversation.',
              'Let the user know about the tasks you can assist with.',
              'If asked about a specific task, use the provided task information to answer the question. howToUse explains how the user can interact with you to perform the task.',
              "You don't need to mention mortgages unless asked.",
            ],
            'Tasks You Can Perform:\n\n' + JSON.stringify(assistantTaskInfo),
          );
          break;
      }

      this.handleCopilotResponse(response);
    } catch (error) {
      this.handleNliError(error, action);
    }
  }

  async executeNliAction(
    actionName,
    apiAction,
    options = {},
    subclassification = '',
  ) {
    const controller = new AbortController();
    const signal = controller.signal;
    options = {
      timeout: 60000,
      signal: signal,
      ...options,
    };

    this.store.commit(`copilot/${ADD_ACTIVE_REQUEST_CONTROLLER}`, controller);
    const response = await apiAction(options);
    this.store.commit(
      `copilot/${REMOVE_ACTIVE_REQUEST_CONTROLLER}`,
      controller,
    );
    // If the request was aborted, don't process the response
    if (signal.aborted) {
      return;
    }

    const action = this.constructActionAnalyticsData(
      actionName,
      response,
      subclassification,
    );

    const training_example = this.constructTrainingExample(
      response,
      actionName,
    );
    action.training_example = training_example;
    response.training_example = training_example;

    this.store.commit(`copilot/${ADD_ACTION_TO_CURRENT_MESSAGE}`, action);

    return response;
  }

  async handleNliError(error, actionName) {
    if (this.copilot(COPILOT_GETTERS.isSending)) {
      this.store.commit(`copilot/${ADD_ACTION_TO_CURRENT_MESSAGE}`, {
        name: actionName,
      });
      await this.addMessage({
        sender: this.copilot(COPILOT_GETTERS.botName),
        text: "I'm sorry, something went wrong. I've notified the team at Polly. In the meantime, you might try again a different way, or try again later.",
      });
    }
    onErrorHandler(`${actionName} error: ${error}`, 'nli-error', [], true);
  }

  async addMessage(message) {
    // Don't add messages from the bot if the bot is no longer sending, this could mean that the user has canceled Copilot response generation
    if (
      message?.sender === this.copilot(COPILOT_GETTERS.botName) &&
      !this.copilot(COPILOT_GETTERS.isSending)
    ) {
      return;
    }

    if (message?.sender === this.copilot(COPILOT_GETTERS.botName)) {
      this.store.commit(`copilot/${UPDATE_CURRENT_MESSAGE}`, {
        ...this.copilot(COPILOT_GETTERS.currentMessage),
        response: message.text,
      });
      const response = await trackMessage(
        this.copilot(COPILOT_GETTERS.currentMessage),
      );
      message.id = response.message_id;
      message.reaction = 0;
    }
    this.store.commit(`copilot/${ADD_MESSAGE}`, message);

    const messageRecord = {
      content: message.text,
      role: 'user',
      sentAt: new Date(),
    };
    if (message.sender === this.copilot(COPILOT_GETTERS.botName)) {
      this.store.dispatch(`copilot/${COPILOT_ACTIONS.setIsSending}`, false);
      this.determineSuggestedActions();
      this.streamAudioFromServer(message);
      messageRecord.role = 'assistant';
    }
    this.scrollToBottom();

    // Only summarize messages of sufficient length, otherwise the
    // summarization is counter productive in that it actually adds tokens instead of reducing
    if (messageRecord.content.length > 100) {
      const response = await this.executeNliAction(
        'summarization',
        async options =>
          summarize(
            {
              prompt: messageRecord.content,
              history: null,
            },
            options,
          ),
      );
      messageRecord.content = response.data.result;
      if (message?.id) {
        // Check if the message has an id, if so, it means the message has been saved to the database and we can add the action. Otherwise, the action will have been stored in the current message and will be added when the message is saved.
        trackMessageAction(message.id, {
          actions: [
            this.constructActionAnalyticsData('summarization', response),
          ],
        });
      }
    }
    this.store.commit(`copilot/${ADD_MESSAGE_TO_HISTORY}`, messageRecord);
    return message;
  }

  async handleCopilotResponse(response) {
    if (response === null) {
      return;
    } else if (
      typeof response === 'object' &&
      'text' in response &&
      'sender' in response
    ) {
      return await this.addMessage(response);
    } else {
      return await this.addMessage({
        sender: this.copilot(COPILOT_GETTERS.botName),
        text: response,
      });
    }
  }

  handleStopCopilotResponse() {
    this.copilot(COPILOT_GETTERS.activeRequestControllers).forEach(controller =>
      controller.abort(),
    );
    this.store.commit(`copilot/${CLEAR_ACTIVE_REQUEST_CONTROLLERS}`);
    this.store.dispatch(`copilot/${COPILOT_ACTIONS.setIsSending}`, false);
  }

  async translateLoanScenario(message) {
    if (!this.pageMatch(loanScenarioPaths)) {
      return this.wrongPageResponse(
        "If you'd like to update your loan scenario please go to the ",
        '/pe/loan-scenario',
        'Loan Scenario Page',
      );
    }

    let isAllEmpty = true;
    const disabledFields = [];

    const promises = [
      this.executeNliAction(
        'json-translation',
        async options =>
          translateJson(
            {
              translationSchema: JSON.stringify(
                this.copilot(COPILOT_GETTERS.loanTranslationSchema),
                null,
                0,
              ),
              prompt: message,
              history: this.historyToString(
                this.copilot(COPILOT_GETTERS.jsonHistoryLength),
              ),
            },
            options,
          ),
        {},
        'loan-translation',
      ).then(response => {
        const { isEmpty, newDisabledFields } = this.handleTranslationResponse(
          response,
          this.copilot(COPILOT_GETTERS.template).loan,
          'loan-translation',
        );
        isAllEmpty = isAllEmpty && isEmpty;
        disabledFields.push(...newDisabledFields);
      }),
      this.executeNliAction(
        'json-translation',
        async options =>
          translateJson(
            {
              translationSchema: JSON.stringify(
                this.copilot(COPILOT_GETTERS.borrowerTranslationSchema),
                null,
                0,
              ),
              prompt: message,
              history: this.historyToString(
                this.copilot(COPILOT_GETTERS.jsonHistoryLength),
              ),
            },
            options,
          ),
        {},
        'borrower-translation',
      ).then(response => {
        const { isEmpty, newDisabledFields } = this.handleTranslationResponse(
          response,
          this.copilot(COPILOT_GETTERS.template).borrower,
          'borrower-translation',
        );
        isAllEmpty = isAllEmpty && isEmpty;
        disabledFields.push(...newDisabledFields);
      }),
      this.executeNliAction(
        'json-translation',
        async options =>
          translateJson(
            {
              translationSchema: JSON.stringify(
                this.copilot(COPILOT_GETTERS.propertyTranslationSchema),
                null,
                0,
              ),
              prompt: message,
              history: this.historyToString(
                this.copilot(COPILOT_GETTERS.jsonHistoryLength),
              ),
            },
            options,
          ),
        {},
        'property-translation',
      ).then(async response => {
        const { isEmpty, newDisabledFields } = this.handleTranslationResponse(
          response,
          this.copilot(COPILOT_GETTERS.template).property,
          'property-translation',
        );
        isAllEmpty = isAllEmpty && isEmpty;
        disabledFields.push(...newDisabledFields);
      }),
      this.executeNliAction(
        'json-translation',
        async options =>
          translateJson(
            {
              translationSchema: JSON.stringify(
                this.copilot(COPILOT_GETTERS.searchTranslationSchema),
                null,
                0,
              ),
              prompt: message,
              history: this.historyToString(
                this.copilot(COPILOT_GETTERS.jsonHistoryLength),
              ),
            },
            options,
          ),
        {},
        'search-translation',
      ).then(response => {
        const { isEmpty, newDisabledFields } = this.handleTranslationResponse(
          response,
          this.copilot(COPILOT_GETTERS.template).criteria,
          'search-translation',
        );
        isAllEmpty = isAllEmpty && isEmpty;
        disabledFields.push(...newDisabledFields);
      }),
    ];

    await Promise.all(promises).catch(error => {
      onErrorHandler(error, 'json-translation', [], true);
    });

    if (disabledFields.length > 0 && !isAllEmpty) {
      return generateResponse(
        loanTranslationSentiments,
        [
          "Unfortunately I couldn't update the following fields because they are disabled: \n\n- " +
            disabledFields.join('\n- '),
        ],
        null,
        null,
        0.5,
        '.',
        '',
      );
    }

    if (disabledFields.length > 0) {
      return (
        "I'm sorry, I can't update the following fields because they are disabled: \n\n- " +
        disabledFields.join('\n- ')
      );
    }

    // Return special response if all translations are empty
    if (isAllEmpty) {
      return generateResponse(
        formUpdateFailureList,
        tryAgainList,
        apologies,
        pleasantryList,
        0.8,
        '.',
        '.',
      );
    }

    if (this.copilot(COPILOT_GETTERS.autoPricingEnabled)) {
      this.getPricing();
      return null;
    }

    return generateResponse([...loanTranslationSentiments], followUps, [
      ...celebrations,
      ...genericTaskCompleteSentiments,
    ]);
  }

  handleTranslationResponse(response, template, translationEvent) {
    let isEmpty = true;
    const { filteredSchema: translation, disabledRemovedKeys } = filterSchema(
      JSON.parse(response.data.result),
      template,
    );
    const newDisabledFields = [
      ...disabledRemovedKeys.map(key => camelCaseToTitleCase(key)),
    ];

    if (Object.keys(translation).length > 0) {
      ChatBus.$emit(translationEvent, translation);
      isEmpty = false;
    }
    return { isEmpty, newDisabledFields };
  }

  async searchRateStack(message) {
    if (!this.pageMatch(loanScenarioPaths)) {
      return this.wrongPageResponse(
        "If you'd like to look up rate stack information please go to the ",
        '/pe/loan-scenario',
        'Loan Scenario Page',
      );
    }

    if (!this.copilot(COPILOT_GETTERS.eligibleProducts)) {
      return generateResponse(
        ["I don't see any eligible products"],
        ['Please re-run pricing and ask again'],
        apologies,
        null,
        0.9,
        '.',
        '.',
      );
    }

    const recalibratedPrompt = await this.normalizeAndTag(
      message,
      SemanticMatchService.rateStackGlossary,
    );
    const selectedColumns = await this.selectColumns(recalibratedPrompt);
    const productRates = SemanticMatchService.instantiateProductRates(
      this.copilot(COPILOT_GETTERS.eligibleProducts),
    );
    let selectedRates = await this.executeSemanticMatchTournament(
      productRates,
      recalibratedPrompt,
      selectedColumns,
    );
    selectedRates = SemanticMatchService.sortRatesByRate(selectedRates);

    const finalSelectionResults = await this.executeNliAction(
      'rate-stack-search',
      async options =>
        semanticMatch(
          {
            prompt: recalibratedPrompt,
            options: SemanticMatchService.formatRateStackSearchResultsString(
              selectedRates,
              selectedColumns,
            ),
            match_count: 5,
          },
          options,
        ),
    );

    const finalSelectedRates = SemanticMatchService.getRatesFromRateIds(
      [finalSelectionResults.data.result],
      productRates,
    );

    const semanticMatchResponse = await this.executeNliAction(
      'rate-explanation',
      async options =>
        explainRates(
          {
            prompt: message,
            additional_context: `${SemanticMatchService.formatRateStackExplanationContext(
              finalSelectedRates,
            )}`,
            history: this.historyToString(
              this.copilot(COPILOT_GETTERS.historyLength),
            ),
          },
          options,
        ),
    );

    return semanticMatchResponse.data.result;
  }

  async executeSemanticMatchTournament(
    productRates,
    recalibratedPrompt,
    selectedColumns,
  ) {
    let selectedRates = productRates;
    while (selectedRates.length > 15) {
      SemanticMatchService.shuffleArray(selectedRates);
      const groups = SemanticMatchService.groupRates(selectedRates);
      const matchCount = SemanticMatchService.determineMatchCount(groups);

      // Make match selections in parallel
      const promises = groups.map(group => {
        group = SemanticMatchService.sortRatesByRate(group);
        return this.executeNliAction('rate-stack-search', async options =>
          semanticMatch(
            {
              prompt: recalibratedPrompt,
              options: SemanticMatchService.formatRateStackSearchResultsString(
                group,
                selectedColumns,
              ),
              match_count: matchCount,
            },
            options,
          ),
        );
      });

      const selectionResults = await Promise.all(promises).catch(error => {
        onErrorHandler(error, 'semantic-match', [], true);
      });

      selectedRates = SemanticMatchService.getRatesFromRateIds(
        selectionResults?.map(result => result?.data?.result) || [],
        productRates,
      );
    }

    return selectedRates;
  }

  async selectColumns(message) {
    const columnSelectionResponse = await this.executeNliAction(
      'column-selection',
      async options =>
        selectColumns(
          {
            prompt: message,
          },
          options,
        ),
    );

    return JSON.parse(columnSelectionResponse.data.result);
  }

  async normalizeAndTag(message, glossary) {
    const normalizationResponse = await this.executeNliAction(
      'normalize-and-tag',
      async options =>
        normalizeAndTag(
          {
            prompt: message,
            glossary: glossary,
          },
          options,
        ),
    );

    return normalizationResponse.data.result;
  }

  async mortgageInfo(
    message,
    additionalRules = [],
    additionalContext = null,
    model = null,
    historyLength = this.copilot(COPILOT_GETTERS.historyLength),
  ) {
    const contextualizationResponse = await this.executeNliAction(
      'contextualizer',
      async options =>
        contextualize(
          {
            prompt: message,
            history: this.historyToString(historyLength),
          },
          options,
        ),
    );

    const contextualizedPrompt = contextualizationResponse.data.result;

    const response = await this.executeNliAction(
      'mortgage-info',
      async options =>
        mortgageInfo(
          {
            prompt: message,
            rag_prompt: contextualizedPrompt,
            additional_rules: additionalRules,
            additional_context: additionalContext,
            model: model,
            history: this.historyToString(historyLength),
          },
          options,
        ),
    );

    return response.data.result;
  }

  async loadLoanScenario(message) {
    if (this.pageMatch(loanScenarioPaths)) {
      const response = await getPricingScenarios(
        this.store.state.organizations.organization.id,
        this.copilot(COPILOT_GETTERS.scenarioRetrievalOptions),
      );
      const scenarioMap = response.results.map(result => ({
        name: result.scenario.name,
        id: result.scenario.id,
      }));

      const optionResponse = await this.executeNliAction(
        'option-selection',
        async options =>
          optionSelection(
            {
              prompt: message,
              options: JSON.stringify(scenarioMap),
            },
            options,
          ),
      );

      if (optionResponse.status && optionResponse.data.result >= 1) {
        const selectedScenario = response.results.find(result => {
          return result.scenario.id == optionResponse.data.result;
        });

        this.store.dispatch('pricing/mergeScenarioWithTemplate', {
          scenario: selectedScenario.pe3_custom_request,
          customParameters: this.copilot(COPILOT_GETTERS.customParameters),
        });
        ChatBus.$emit('load-scenario');

        return generateResponse(
          [...loadedScenarioSentiments, ...genericTaskCompleteSentiments],
          followUps,
          celebrations,
        );
      }
      // Failed to load scenario
      return generateResponse(
        [...loadedScenarioFailureSentiments],
        tryAgainList,
        apologies,
        null,
        0.8,
        '.',
        '.',
      );
    }

    return this.wrongPageResponse(
      "If you'd like to load a loan scenario please go to the ",
      '/pe/loan-scenario',
      'Loan Scenario Page',
    );
  }

  async saveLoanScenario(message) {
    if (this.pageMatch(loanScenarioPaths)) {
      const scenarioNameResponse = await this.executeNliAction(
        'name-extraction',
        async options =>
          nameExtraction(
            {
              prompt: message,
              additional_rules: [
                'Extract the name of the scenario. (ex: {message: Save this scenario as investment property, name: investment property)',
              ],
            },
            options,
          ),
      );

      if (
        scenarioNameResponse.status &&
        scenarioNameResponse.data.result.toLowerCase() !== 'none'
      ) {
        await saveScenario(
          scenarioNameResponse.data.result,
          this.store.state.pricing.pricingTemplate,
          this.copilot(COPILOT_GETTERS.customParameters),
          this.store.state.organizations.organization.id,
        );

        return generateResponse(
          [...savedScenarioSentiments, ...genericTaskCompleteSentiments],
          followUps,
        );
      }
      // Failed to load scenario
      return generateResponse(
        [...savedScenarioFailureSentiments],
        tryAgainList,
        apologies,
        null,
        0.8,
        '.',
        '.',
      );
    }

    return this.wrongPageResponse(
      "If you'd like to save a loan scenario please go to the ",
      '/pe/loan-scenario',
      'Loan Scenario Page',
    );
  }

  async explainIneligibility(message) {
    if (!this.pageMatch(loanScenarioPaths)) {
      return this.wrongPageResponse(
        "If you'd like to interact with products and pricing please go to the ",
        '/pe/loan-scenario',
        'Loan Scenario Page',
      );
    }

    if (!this.copilot(COPILOT_GETTERS.ineligibleProducts)) {
      return generateResponse(
        ["I don't see any ineligible products"],
        ['Please re-run pricing and ask again'],
        apologies,
        null,
        0.9,
        '.',
        '.',
      );
    }

    const optionResponse = await this.executeNliAction(
      'option-selection',
      async options =>
        optionSelection(
          {
            prompt: message,
            options: JSON.stringify(
              this.copilot(COPILOT_GETTERS.ineligibleProducts).map(product => ({
                name: product.name,
                id: product.id,
              })),
            ),
          },
          options,
        ),
    );

    const trimmedOptionId = optionResponse.data.result.replace(/"/g, '');
    const selectedProduct = this.copilot(
      COPILOT_GETTERS.ineligibleProducts,
    ).find(product => {
      return product.id == trimmedOptionId;
    });
    if (optionResponse.status && trimmedOptionId != -1 && selectedProduct) {
      return await this.generateIneligibilityExplanation(
        message,
        selectedProduct,
      );
    }

    // Failed to find ineligible product by name
    return generateResponse(
      [...ineligibleProductFailureSentiments],
      tryAgainList,
      apologies,
      null,
      0.8,
      '.',
      '.',
    );
  }

  async getAudienceParamOptions() {
    let audienceOptions = this.store.getters['pricing/audienceOptions'];

    if (!audienceOptions || audienceOptions.length === 0) {
      try {
        const versionId =
          this.store.getters['pricingVersionDetails/configuration']?.id;
        await this.store.dispatch(
          'pricing/fetchAudiencesForVersion',
          versionId,
        );
        audienceOptions = this.store.getters['pricing/audienceOptions'];
      } catch (error) {
        onErrorHandler(error, 'nli-get-audience-options', [], true);
      }
    }

    return audienceOptions || [];
  }

  async generateParamTranslationMap() {
    const audienceParamOptions = await this.getAudienceParamOptions();

    return {
      AudienceId: audienceParamOptions,
    };
  }

  async generateIneligibilityExplanation(message, ineligibleProduct) {
    const loanScenarioResult = {
      ...this.copilot(COPILOT_GETTERS.pricingScenario),
    };
    const selectedProductCode = ineligibleProduct['code'];
    loanScenarioResult['Search']['ProductCodes'] = [selectedProductCode];
    loanScenarioResult['paramTranslationMap'] =
      await this.generateParamTranslationMap();

    const loanScenarioAnalysis = await scenarioAnalysis(loanScenarioResult);

    const ineligibleProductAnalysis =
      loanScenarioAnalysis.data.ineligibleProducts[0];

    if (ineligibleProductAnalysis.solutions.length === 0) {
      return (
        "Oops! It seems like I've hit a snag analyzing this rule. I'm really sorry for the " +
        "inconvenience. Rest assured, I've already flagged this for further review. Our team is " +
        "always working on improvements and we'll make sure to enhance our ability to handle " +
        'situations like this in the future. Thank you for your patience and understanding.'
      );
    }

    // only take the first solution for now, handle multiple later
    const ineligibilitySolution = ineligibleProductAnalysis.solutions[0];
    const response = await this.executeNliAction(
      'explain-ineligibility',
      async options =>
        explainLoanIneligibility(
          {
            prompt: message,
            additional_context: `${
              ineligibleProduct.name
            } Failed Requirements: \n${this.parseSolutionRequirements(
              ineligibilitySolution,
            )}`,
          },
          options,
        ),
    );

    return response.data.result;
  }

  async handleUiIneligibilityInteraction(
    ineligibleProduct,
    source = messageSources.INELIGIBILITYEXPAND,
  ) {
    const newMessageText = `Why is the ${ineligibleProduct.name} product ineligible?`;
    this.processNewMessage(newMessageText, source);

    const ineligibilityExplanation =
      await this.generateIneligibilityExplanation(
        newMessageText,
        ineligibleProduct,
      );

    return await this.handleCopilotResponse(ineligibilityExplanation);
  }

  async explainNearMiss(message) {
    if (!this.pageMatch(loanScenarioPaths)) {
      return this.wrongPageResponse(
        "If you'd like to interact with products and pricing please go to the ",
        '/pe/loan-scenario',
        'Loan Scenario Page',
      );
    }
    if (!this.copilot(COPILOT_GETTERS.ineligibleProducts)) {
      return generateResponse(
        ["I don't see any ineligible products"],
        ['Please re-run pricing and ask again'],
        apologies,
        null,
        0.9,
        '.',
        '.',
      );
    }
    const pricingScenarioTemplate = clone(
      this.copilot(COPILOT_GETTERS.pricingScenario),
    );
    const ineligibleProductCodes = this.copilot(
      COPILOT_GETTERS.ineligibleProducts,
    ).map(product => product.code);
    pricingScenarioTemplate['Search']['ProductCodes'] = ineligibleProductCodes;
    const nearMissResults = await nearMiss({
      ...pricingScenarioTemplate,
      currentOptions: this.copilot(COPILOT_GETTERS.optionMaps),
      paramTranslationMap: await this.generateParamTranslationMap(),
    });
    const nearMissProducts = nearMissResults
      ? nearMissResults.data.eligibleProducts
      : [];
    const nearMissContext = this.parseNearMissContext(nearMissProducts);
    if (nearMissContext.length == 0) {
      return await this.mortgageInfo(message, [
        "Explain to the user that you're unable to find any additional products that could easily be made eligible right now.",
        'Do not make any additional offers or suggestions.',
      ]);
    }
    const response = await this.executeNliAction(
      'explain-near-miss',
      async options =>
        explainNearMiss(
          {
            prompt: message,
            additional_context: nearMissContext,
          },
          options,
        ),
    );
    return response.data.result;
  }

  explainPricing(isPricingRequest = false) {
    if (this.copilot(COPILOT_GETTERS.bestPrices)) {
      this.addMessage({
        sender: this.copilot(COPILOT_GETTERS.botName),
        text: this.generatePricingSuccessResponse(
          this.copilot(COPILOT_GETTERS.bestPrices),
          isPricingRequest,
        ),
      });
    } else {
      this.addMessage({
        sender: this.copilot(COPILOT_GETTERS.botName),
        text: generateResponse(
          ["I don't see any pricing info"],
          ['Please re-run pricing and ask again'],
          apologies,
          null,
          0.9,
          '.',
          '.',
        ),
      });
    }
  }

  parseNearMissContext(nearMissProducts) {
    let result = '';
    for (const product of nearMissProducts) {
      const product_name = `Product: ${product.name}\n`;
      result = result ? result + `\n\n${product_name}` : product_name;
      const solution = product?.solutions[0];
      for (const parameter in solution) {
        const value = solution[parameter];
        result += ` - ${parameter} must be ${value}\n`;
      }
    }
    return result;
  }

  generatePricingSuccessResponse(minPrices, isPricingRequest = false) {
    const minPricesList = Object.entries(minPrices).map(
      ([key, value]) =>
        `- ${key}: ${value.price.rate}% rate on the ${value.product.name} product`,
    );
    const bestPriceStatement =
      minPricesList.length > 1
        ? 'The lowest rate par prices...'
        : 'The lowest rate par price...';

    const followUp = minPricesList.length
      ? ['\n\n' + bestPriceStatement + ` \n\n${minPricesList.join('\n')}`]
      : followUps;

    const eligibleSentiments = isPricingRequest
      ? [...pricingRetrievalSentiments, ...genericTaskCompleteSentiments]
      : affirmatives;

    const sentiments = minPricesList.length
      ? eligibleSentiments
      : ['It looks like there are no eligible products'];

    const followUpEndChar = minPricesList.length ? '.' : '?';

    return generateResponse(
      sentiments,
      followUp,
      null,
      null,
      null,
      '.',
      followUpEndChar,
    );
  }

  getPricing() {
    if (this.pageMatch(loanScenarioPaths)) {
      this.store.commit(`copilot/${SET_PRICING_REQUESTED}`, true);
      ChatBus.$emit('get-pricing');

      return null;
    }

    return this.wrongPageResponse(
      "If you'd like to get pricing please go to the ",
      '/pe/loan-scenario',
      'Loan Scenario Page',
    );
  }

  generateSchemas(
    template = {},
    customParameters = {},
    currentPricingParameters = {},
  ) {
    // Call your functions to generate the schemas here
    const loanCustomParameters = filterObject(
      customParameters,
      param => param?.valueCategory?.toLowerCase() === 'loan',
    );
    this.store.commit(
      `copilot/${SET_LOAN_TRANSLATION_SCHEMA}`,
      generateLoanTranslationSchema(
        template,
        loanCustomParameters,
        currentPricingParameters?.loan || {},
      ),
    );

    const borrowerCustomParameters = filterObject(customParameters, param =>
      ['borrower', 'custom'].includes(param?.valueCategory?.toLowerCase()),
    );
    this.store.commit(
      `copilot/${SET_BORROWER_TRANSLATION_SCHEMA}`,
      generateBorrowerTranslationSchema(
        template,
        borrowerCustomParameters,
        currentPricingParameters?.borrower || {},
      ),
    );

    const propertyCustomParameters = filterObject(
      customParameters,
      param => param?.valueCategory?.toLowerCase() === 'property',
    );
    this.store.commit(
      `copilot/${SET_PROPERTY_TRANSLATION_SCHEMA}`,
      generatePropertyTranslationSchema(
        template,
        propertyCustomParameters,
        currentPricingParameters?.property || {},
      ),
    );

    this.store.commit(
      `copilot/${SET_SEARCH_TRANSLATION_SCHEMA}`,
      generateSearchTranslationSchema(
        template,
        currentPricingParameters?.criteria || {},
      ),
    );

    this.store.commit(`copilot/${SET_OPTION_MAPS}`, {
      ...extractOptions(this.copilot(COPILOT_GETTERS.loanTranslationSchema)),
      ...extractOptions(
        this.copilot(COPILOT_GETTERS.borrowerTranslationSchema),
      ),
      ...extractOptions(
        this.copilot(COPILOT_GETTERS.propertyTranslationSchema),
      ),
      ...extractOptions(this.copilot(COPILOT_GETTERS.searchTranslationSchema)),
    });
  }

  pageMatch(paths, currentPath = this.vueRouter.currentRoute.path) {
    return paths.some(path => new RegExp(path).test(currentPath));
  }

  wrongPageResponse(clarification = null, link = null, linkText = null) {
    return {
      sender: this.copilot(COPILOT_GETTERS.botName),
      text: `I'm sorry, I can't do that here. ${clarification}`,
      link: link,
      linkText: linkText,
    };
  }

  sendAudioMessage(audioBase64, source = messageSources.VOICE) {
    transcribeAudio(audioBase64)
      .then(response => {
        if (response.status) {
          const action = this.constructActionAnalyticsData(
            'audio-transcription',
            response,
          );
          this.sendMessage(response.data.result, source);
          this.store.commit(`copilot/${ADD_ACTION_TO_CURRENT_MESSAGE}`, action);
        } else {
          onErrorHandler(
            `Error during audio transcription: ${response.data.results}`,
            'audio-transcription',
            [],
            true,
          );
        }
      })
      .catch(error => {
        onErrorHandler(
          `Error during audio transcription: ${error}`,
          'audio-transcription',
          [],
          true,
        );
      });
  }

  streamAudioFromServer(message) {
    if (!this.copilot(COPILOT_GETTERS.vocalResponseEnabled)) {
      return;
    }
    // Stop currently playing audio
    this.store.commit(`copilot/${STOP_AUDIO}`);

    // Create an axios request to send the data
    axios
      .post(
        '/nli/tts',
        { prompt: message.text },
        {
          headers: { 'Content-Type': 'application/json' },
          responseType: 'arraybuffer',
        },
      )
      .then(response => {
        // Create a new Blob from the response data
        const audioBlob = new Blob([response.data], {
          type: 'audio/ogg; codecs=opus',
        });

        // Create URL from the Blob
        const audioUrl = URL.createObjectURL(audioBlob);
        this.store.commit(`copilot/${PLAY_AUDIO}`, audioUrl);

        // Only track if usage header exists
        const usageHeader = response.headers['x-usage-data'];
        if (usageHeader) {
          try {
            const usageData = JSON.parse(usageHeader);
            const mappedResponse = {
              data: {
                usage: usageData,
                result: 'success',
              },
            };

            const action = this.constructActionAnalyticsData(
              'audio-tts',
              mappedResponse,
            );
            trackMessageAction(message.id, {
              actions: [action],
            });
          } catch (error) {
            onErrorHandler(
              `Error Processing Audio Usage Data: ${error}`,
              'audio-streaming',
              [],
              true,
            );
          }
        }
      })
      .catch(error =>
        onErrorHandler(
          `Error Streaming Audio: ${error}`,
          'audio-streaming',
          [],
          true,
        ),
      );
  }

  constructTrainingExample(response, actionName) {
    if (actionName === 'json-translation') {
      return this.formatTrainingExample(
        response,
        'translation_system_message',
        this.store.jsonHistoryLength,
      );
    } else if (actionName === 'traffic-cop') {
      return this.formatTrainingExample(
        response,
        'traffic_cop_system_message',
        this.store.trafficCopHistoryLength,
      );
    } else if (actionName === 'mortgage-info') {
      return this.formatTrainingExample(
        response,
        'mortgage_info_system_message',
        this.store.historyLength,
        response?.data?.system_message,
      );
    } else if (actionName === 'contextualizer') {
      return this.formatTrainingExample(
        response,
        'CONTEXT_FILL_SYSTEM_MESSAGE',
        this.store.historyLength,
      );
    } else if (actionName === 'explain-ineligibility') {
      return this.formatTrainingExample(
        response,
        'INELIGIBILITY_EXPLANATION_SYSTEM_MESSAGE',
        this.store.ineligibilityHistoryLength,
      );
    } else if (actionName === 'rate-stack-search') {
      return this.formatTrainingExample(
        response,
        'semantic_match_system_message',
        0,
      );
    } else if (actionName === 'normalize-and-tag') {
      return this.formatTrainingExample(response, 'system_message', 0);
    } else if (actionName === 'rate-explanation') {
      return this.formatTrainingExample(
        response,
        'rate_explanation_system_message',
        this.store.historyLength,
        response?.data?.system_message,
      );
    }

    return '';
  }

  constructActionAnalyticsData(actionName, response, subclassification = '') {
    return {
      name: actionName,
      result: response.data.result,
      subclassification: subclassification,
      ai_model: response.data.usage.model,
      model_provider: response.data.usage.model_provider,
      characters: response.data.usage.characters,
      audio_seconds: response.data.usage.audio_seconds,
      prompt_tokens: response.data.usage.prompt_tokens,
      completion_tokens: response.data.usage.completion_tokens,
      total_tokens: response.data.usage.total_tokens,
      cost: response.data.usage.cost,
      history: response.data?.history || '',
    };
  }

  formatTrainingExample(
    response,
    systemMessageVariableString,
    historyLength = 2,
    system_message = null,
  ) {
    let systemContent = '';

    if (system_message) {
      systemContent = system_message;
    } else {
      let conversationHistory = '';
      if (historyLength > 0) {
        conversationHistory = `+ """\\n\\nConversation History: \\n ${this.historyToString(
          historyLength,
        )}"""`;
      }

      systemContent = systemMessageVariableString + ' ';
      if (response?.data?.additional_context) {
        systemContent +=
          '+ """\\n\\n' + response?.data?.additional_context + '"""';
      }
      if (response?.data?.translation_schema) {
        systemContent +=
          `+ '\\n\\n'` +
          JSON.stringify(JSON.stringify(response?.data?.translation_schema));
      }
      systemContent += conversationHistory;
    }

    const prompt = response?.data?.templated_prompt || response?.data?.prompt;

    const formatted = `{
        "messages": [
          {
            "role": 'system',
            "content": ${systemContent},
          },
          {
            "role": 'user',
            "content": "${prompt.replace(/"/g, '\\"')}",
          },
          {
            "role": 'assistant',
            "content": "${response.data.result.replace(/"/g, '\\"')}",
          },
        ],
      },\n`;

    return formatted;
  }

  parseSolutionRequirements(solutionRequirements) {
    let result = ''; // Initialize the result string

    Object.entries(solutionRequirements.parameters).forEach(
      ([parameter, details], i) => {
        result += `${i + 1}. ${parameter} ${this.determineSolutionRequirement(
          details,
        )} `;
        result += `(failed because ${parameter} is currently ${details.parameterValue})\n\n`;
      },
    );

    return result;
  }

  determineSolutionRequirement(solutionRequirement) {
    const requirements = solutionRequirement.operators;
    const requirementStrings = Object.entries(requirements).map(
      ([operator, requirmentData]) => {
        // Spread operator so we can mutate without modifying the original requirements
        const optionsArray = requirmentData?.example_options?.length
          ? [...requirmentData.example_options]
          : [...requirmentData.requirements];
        if (
          requirmentData.options_count &&
          requirmentData?.example_options?.length < requirmentData.options_count
        ) {
          optionsArray.push(
            'and ' +
              (requirmentData.options_count -
                requirmentData.example_options.length) +
              ' more',
          );
        }
        return `${this.determineSolutionRequirementDirective(
          operator,
          requirmentData,
        )} (${optionsArray.join(', ')})`;
      },
    );

    return `${requirementStrings.join(' and ')}`;
  }

  determineSolutionRequirementDirective(operator, requirementData) {
    let directive = 'must be';

    // In the case of contains when a rule causes ineligibility, or excludes when a rule allows eligibility
    if (operator == '!=') {
      directive = 'cannot be';
    }

    // This means we are doing a numeric comparison, in which case we need to include the operator for context
    if (!['==', '!='].includes(operator)) {
      directive = directive += ` ${operator}`;
    }

    // We add this language to make multi requirement explanations like contains/excludes more clear
    if (requirementData?.example_options?.length > 1) {
      directive = directive += ' any of these:';
    }
    if (
      requirementData?.example_options?.length != requirementData?.options_count
    ) {
      directive = directive.replace('any of these:', 'any option like these:');
    }

    return directive;
  }

  parseFieldErrors(errors) {
    const fields = errors
      .map(error => error.split(' ')[0])
      .map(error => error.split('.')[1]);
    const fieldDisplayNames = fields.map(field => {
      if (field.includes('countyFipsCode')) {
        field = field.replace('countyFipsCode', 'county');
      }
      field = camelCaseToTitleCase(field);

      return field;
    });

    const bulletList = fieldDisplayNames.map(field => `- ${field}`).join('\n');

    return bulletList;
  }

  historyToString(
    mostRecentNumber = this.copilot(COPILOT_GETTERS.historyLength),
  ) {
    const trimmedHistory = this.trimHistoryToMostRecentAssistant(
      this.copilot(COPILOT_GETTERS.currentHistory),
    );
    const messages =
      mostRecentNumber === 0
        ? []
        : trimmedHistory
            .slice(-mostRecentNumber)
            .map(
              message =>
                '(' +
                message.sentAt.toLocaleString() +
                ') ' +
                message.role +
                ': ' +
                message.content,
            );
    return messages.join('\n');
  }

  // This method trims the "current" user message from the history if it exists
  trimHistoryToMostRecentAssistant(
    history = this.copilot(COPILOT_GETTERS.currentHistory),
  ) {
    // Reverse the messages, so they start from the end
    const reversedHistory = [...history].reverse();
    // Find the index of the first Assistant message
    const assistantMessageIndex = reversedHistory.findIndex(
      message => message.role === 'assistant',
    );

    // If an assistant message is found, splice out the messages before it
    if (assistantMessageIndex !== -1) {
      reversedHistory.splice(0, assistantMessageIndex);
    }

    return reversedHistory.reverse();
  }

  onLoanTemplateUpdated(template) {
    this.store.commit(`copilot/${SET_TEMPLATE}`, template);
    this.generateSchemas(
      template,
      this.copilot(COPILOT_GETTERS.customParameters),
      this.copilot(COPILOT_GETTERS.currentPricingParameters),
    );
  }

  onPricingParametersUpdated(currentPricingParameters) {
    this.store.commit(
      `copilot/${SET_CURRENT_PRICING_PARAMETERS}`,
      currentPricingParameters,
    );
    this.generateSchemas(
      this.copilot(COPILOT_GETTERS.template),
      this.copilot(COPILOT_GETTERS.customParameters),
      currentPricingParameters,
    );
  }

  onPricingError(errors) {
    const bulletList = this.parseFieldErrors(errors);

    this.addMessage({
      sender: this.copilot(COPILOT_GETTERS.botName),
      text: generateResponse(
        fieldIssuesList,
        ['\n'.concat(bulletList)],
        apologies,
        null,
        0.5,
        ':',
        '',
      ),
    });
    this.store.commit(`copilot/${SET_PRICING_REQUESTED}`, false);
  }

  onPricingSuccess(pricingData) {
    this.store.commit(`copilot/${SET_BEST_PRICES}`, pricingData.minPrices);
    this.store.commit(
      `copilot/${SET_ELIGIBLE_PRODUCTS}`,
      pricingData.eligibleProducts,
    );
    this.store.commit(
      `copilot/${SET_INELIGIBLE_PRODUCTS}`,
      pricingData.ineligibleProducts,
    );
    this.store.commit(
      `copilot/${SET_PRICING_SCENARIO}`,
      pricingData.pricingScenario,
    );

    if (this.copilot(COPILOT_GETTERS.pricingRequested)) {
      this.explainPricing(true);
    }
    this.store.commit(`copilot/${SET_PRICING_REQUESTED}`, false);
    this.determineSuggestedActions();
  }

  onCustomParametersUpdated(customParameters) {
    this.store.commit(
      `copilot/${SET_CUSTOM_PARAMETERS}`,
      customParameters || {},
    );
    this.generateSchemas(
      this.copilot(COPILOT_GETTERS.template),
      customParameters,
      this.copilot(COPILOT_GETTERS.currentPricingParameters),
    );
  }
}
