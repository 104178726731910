import {
  getCreditCost,
  getModeAndWorkflowFromLoanData,
  loanTotalByType,
  addAllInPriceToLosCustomCredit,
} from '@pe/functions/helpers.js';
import { getTerms } from '@pe/services/loanTerms.js';
import {
  getAudiences,
  getEntities,
  getProduct,
} from '@pe/services/configurations.js';
import { getPricingScenarioUserConfig } from '@pe/services/pricingScenarios.js';
import { getPERuleFields } from '@pe/services/rules.js';
import merge from 'lodash/merge';

import {
  ADJUSTMENT_KIND,
  ADJUSTMENT_NAME_MATCH,
  LOCK_EXP_DATE_SCHED_RULE,
  LOCK_RATE_ACTIONS,
  LOCK_RATE_STATUS,
  RULE_TARGET,
  lockDeskStatus,
  pricerModes,
  LOCK_REQUEST_STATUS,
} from '@shared/constants';
import {
  BACKEND_TOTAL_LOAN_AMOUNT_CALCULATION,
  MORTGAGE_COACH_INTEGRATION,
} from '@shared/constants/switches.js';
import api from '@shared/services/api.js';
import { hasValidExternalAccount } from '@shared/services/coreApi.js';
import { calculateCreditCost } from '@pe/functions/helpers.js';
import { getDate, strToPrecision } from '@shared/utils/converters.js';
import {
  add as addDays,
  getNextBusinessDate,
  getPrevBusinessDate,
  getUTCDifferenceInDays,
  parseISO,
} from '@shared/utils/dates.js';
import {
  SET_ALL_IN_PRICE_INVALID,
  SET_CURRENT_AVAILABLE_PRODUCTS,
  SET_CUSTOM_CREDIT_DATA,
  SET_CUSTOM_LOAN_TERMS,
  SET_CACHED_TEMPLATE,
  SET_ERROR_MESSAGE,
  SET_EXTERNAL_VISIBILITY_OBJECT,
  SET_FLOAT_DOWN_POLICY,
  SET_INITIAL_LOCK_PRODUCT_INFO,
  SET_IS_AUTO_APPROVED,
  SET_IS_PRICING_INITIALIZING,
  SET_LOADING_DYNAMIC_PRICING_DETAILS,
  SET_LOAN_DATA,
  SET_LOAN_STATUS_FOR_REFRESH,
  SET_LOS_LOAN_DATA,
  SET_LOS_USER,
  SET_MORTGAGE_COACH_ACCOUNT,
  SET_MORTGAGE_INSURANCE_DATA,
  SET_PE_REQUEST_DATA,
  SET_PE_RULE_FIELDS_CACHE,
  SET_PENDING_PERSISTED_ADJUSTMENTS,
  SET_POLICY_LIMIT_MESSAGE,
  SET_PREVIEW_PRICE,
  SET_PRICE,
  SET_PRICING_SCENARIO_RESULT,
  SET_PRICING_TEMPLATE,
  SET_PRODUCT_INFO,
  SET_REQUESTING_LOCK,
  SET_SELECTED_PRICE_PRODUCT_INFO,
  SET_SELECTED_PRICING_VERSION,
  SET_SELECTED_PRODUCT_PRICES,
  SET_SELECTED_RELOCK_PERIOD,
  SET_SELECTING_PRICE,
  SET_SELL_SIDE_LOCKED_AT,
  SET_SELL_SIDE_LOCK_PERIOD,
  SET_SELL_SIDE_EXPIRES_AT,
  SET_USER_SCENARIO_CONFIG,
  SET_SORTED_PRODUCTS,
  SET_IS_LOADING_LOCK,
  SET_DOCUMENT_BY_CONFIGURATION,
  SET_IS_EXCEPTION_FIELDS_VALID,
  SET_PRICE_EXCEPTION,
} from './mutationTypes.js';

import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import {
  deleteLockRequest,
  postInitialLockRequest,
} from '@pe/services/lockRequests';
import { onErrorHandler } from '@shared/utils/errorHandlers';
import { getProductGuidelineDocuments } from '@useradmin/services/api/guidelineDocuments';

export default {
  namespaced: true,
  state: {
    audiences: [],
    crossWorkflow: null,
    currentAvailableProducts: [],
    customCreditData: {},
    cachedTemplate: null,
    externalVisibilityObject: {},
    floatDownPolicy: {},
    isAutoApproved: false,
    isLoadingDynamicPricingDetails: false,
    isPricingInitializing: true,
    loanData: null,
    loanStatusForRefresh: null,
    lockDeskStatus: null,
    lockExceptionPolicy: {},
    lockExtensionPolicy: {},
    losLoanData: null,
    losLoanId: null,
    losUser: null,
    pendingPersistedAdjustments: [],
    productInfo: null,
    mortgageCoachAccount: {},
    mortgageInsuranceData: {},
    orgId: null,
    policyLimitMessage: '',
    previousWorkflow: null,
    previousCrossWorkflow: null,
    pricerMode: null,
    pricingVersions: [],
    requestedRepriceLock: {},
    requestingLock: false,
    selectedAudienceId: null,
    selectedPrice: null,
    selectingPrice: false,
    selectedPricingVersion: {},
    selectedPricingVersionId: null,
    selectedProductPrices: [],
    selectedRelockPeriod: null,
    workflow: null,
    workflowPermissions: null,
    pricingTemplate: {},
    errorMessage: undefined,
    customLoanTerms: [],
    pricingScenarioResult: {},
    peRequestData: {},
    previewPrice: null,
    peRuleFieldsCache: null,
    sellSideLockedAt: null,
    sellSideLockPeriod: null,
    sellSideExpiresAt: null,
    userScenarioConfig: {},
    sortedProducts: [],
    priceAfterException: null,
    isLoadingLock: false,
    documentsByConfigurations: {},
    isAllInPriceInvalid: false,
    allInPriceData: {},
    isExceptionFieldsValid: false,
    priceExceptionValue: null,
    priceExceptionPercent: null,
  },
  getters: {
    allInPriceData: state => state.allInPriceData,
    isAllInPriceInvalid: state => state.isAllInPriceInvalid,
    pricingScenarioResult: state => state.pricingScenarioResult,
    peRuleFieldsCache: state => state.peRuleFieldsCache,
    peRequestData: state => state.peRequestData,
    priceAfterException: state => state.priceAfterException,
    selectedPricingVersion: state => state.selectedPricingVersion,
    selectedPrice: state => {
      // Prevents exceptions from being duplicated into adjustments
      const filteredAdjustments =
        state.selectedPrice?.adjustments?.filter(
          adjustment =>
            !(
              adjustment.ruleName?.startsWith(
                ADJUSTMENT_NAME_MATCH.EXCEPTION,
              ) && adjustment.target === RULE_TARGET.PRICE
            ),
        ) || [];
      const property = state?.loanData?.property;
      const borrowerFirstName = state?.loanData?.borrower?.firstName;
      const borrowerLastName = state?.loanData?.borrower?.lastName;
      return {
        ...state.selectedPrice,
        adjustments: filteredAdjustments,
        property,
        borrowerFirstName,
        borrowerLastName,
        estimatedClosingDate: state?.loanData?.loan?.estimatedClosingDate,
      };
    },
    previewPrice: state => state.previewPrice,
    selectedPriceWithExceptions: state => {
      return state.previewPrice || state.selectedPrice;
    },
    lockDeskStatus: state => state.lockDeskStatus,
    pricerMode: state => state.pricerMode,
    requestedRepriceLock: state => state.requestedRepriceLock,
    productInfo: state => state.productInfo,
    selectedRateComputed: state => {
      return strToPrecision(state.selectedPrice?.rate, 3);
    },
    losLoanData: state => {
      return state.losLoanData;
    },
    currentLock: state => {
      if (!state.loanData?.lockRequests) {
        return {};
      }
      const { currentLock, initialLock, cancelledLock, pendingLock } =
        state.loanData.lockRequests;
      const lock = currentLock || cancelledLock || initialLock || pendingLock;
      // initial lock exists first
      // current lock is set after a second lock
      // attempt to return current lock, fall back on initial lock
      return {
        ...lock,
        ...getTerms(lock?.productCode, lock?.originalPriceRequest),
      };
    },
    currentLockEndDate: (_, getters) => {
      return (
        getters.currentLock.extendedExpirationDate ||
        getters.currentLock.expirationDate
      );
    },
    currentLockPeriod: (_, getters) => {
      return getters.currentLock?.lockPeriod || 0;
    },
    currentLockDaysLeft: (_, getters) => {
      if (!getters.currentLockEndDate) {
        return null;
      }
      return getUTCDifferenceInDays(
        parseISO(getters.currentLockEndDate),
        new Date(),
      );
    },
    customCreditData: state => state.customCreditData || {},
    origination: state =>
      state.customCreditData?.origination?.originationPercent,
    discount: state => state.customCreditData?.discount?.discountPercent,
    lenderPaid: state => state.customCreditData?.lenderPaid?.lenderPaidPercent,
    applyMaximumLenderCredit: state =>
      state.customCreditData.applyMaximumLenderCredit,
    totalEstPrepaidAndClosingCostPercentage: state =>
      state.customCreditData.totalEstPrepaidAndClosingCostPercentage,
    totalEstPrepaidAndClosingCostAmount: state =>
      state.customCreditData.totalEstPrepaidAndClosingCostAmount,
    availableLenderCreditPercent: state =>
      state.customCreditData.availableLenderCreditPercent,
    availableLenderCreditAmount: state =>
      state.customCreditData.availableLenderCreditAmount,
    pendingLock: state => state.loanData?.lockRequests?.pendingLock || {},
    audienceOptions: state => {
      return state.audiences
        ? state.audiences.map(audience => ({
            label: audience.name,
            value: audience.id,
          }))
        : [];
    },
    selectedAudienceId: state => state.selectedAudienceId,
    selectedAudience: state => {
      const { audiences, selectedAudienceId } = state;
      return audiences.find(aud => aud.id === selectedAudienceId);
    },
    selectedPricingVersionId: state => state.selectedPricingVersionId,
    isLoanLockedOrPending: state => {
      const {
        LOCKED,
        LOCK_PENDING,
        REPRICE_PENDING,
        EXTENSION_PENDING,
        CANCELLATION_PENDING,
        RELOCK_PENDING,
        PRICE_EXCEPTION_PENDING,
        EXPIRED,
        FLOAT_DOWN_PENDING,
      } = LOCK_RATE_STATUS;
      return [
        LOCKED,
        LOCK_PENDING,
        REPRICE_PENDING,
        EXTENSION_PENDING,
        CANCELLATION_PENDING,
        RELOCK_PENDING,
        PRICE_EXCEPTION_PENDING,
        EXPIRED,
        FLOAT_DOWN_PENDING,
      ].includes(state.loanData?.lockRateStatus);
    },
    isLoanLocked: state => {
      const { LOCKED } = LOCK_RATE_STATUS;
      return [LOCKED].includes(state.loanData?.lockRateStatus);
    },
    loanData: state => state.loanData,
    loanStatusForRefresh: state => state.loanStatusForRefresh,
    hasFloatRequestEnabled: state =>
      state.workflowPermissions?.has_float_request_enabled,
    hasLockDeskSelfServiceEnabled: state =>
      state.workflowPermissions?.has_lock_desk_self_service_enabled,
    hasCustomCreditEnabled: state =>
      state.workflowPermissions?.has_custom_credit_enabled,
    lockExpirationSettings: state =>
      state.workflowPermissions?.lock_expiration_settings,
    hasInAppReviewalEnabled: state =>
      state.workflowPermissions?.has_in_app_reviewal_enabled,
    hideCancelLockRequestButton: state =>
      state.workflowPermissions?.hide_cancel_lock_request_button,
    hideCorporateOptionFromLoanOfficer: state =>
      state.workflowPermissions?.hide_corporate_option_from_loan_officer,
    hideBranchOptionFromLoanOfficer: state =>
      state.workflowPermissions?.hide_branch_option_from_loan_officer,
    hasSellSideAnalysisEnabled: state =>
      state.workflowPermissions?.has_sell_side_analysis_enabled,
    disableEncompassLoanExitPopup: state =>
      state.workflowPermissions?.disable_encompass_loan_exit_popup,
    isAutoApproved: state => state.isAutoApproved,
    isExceptionFieldsValid: state => state.isExceptionFieldsValid,
    initialRateSheetId: state => {
      return (
        state.loanData?.lockRequests?.initialLock?.rateSheetId ||
        state.loanData?.lockRequests?.pendingLock?.rateSheetId
      );
    },
    rateSheetDate: state => {
      return (
        state.loanData?.lockRequests?.currentLock?.rateSheetId ||
        state.loanData?.lockRequests?.pendingLock?.rateSheetId ||
        state.loanData?.lockRequests?.initialLock?.rateSheetId
      );
    },
    workflow: state => {
      let mode, step;
      if (state.workflow) {
        [mode, step] = state.workflow.split('.');
      }
      return { mode, step };
    },
    crossWorkflow: state => {
      let mode, step;
      if (state.crossWorkflow) {
        [mode, step] = state.crossWorkflow.split('.');
      }
      return { mode, step };
    },
    fullWorkflow: state => state.workflow,
    lockExtensionPolicy: state => state.lockExtensionPolicy,
    lockExceptionPolicy: state => state.lockExceptionPolicy,
    previousWorkflow: state => {
      let mode, step;
      if (state.previousWorkflow) {
        [mode, step] = state.previousWorkflow.split('.');
      }
      return { mode, step };
    },
    previousCrossWorkflow: state => {
      let mode, step;
      if (state.previousCrossWorkflow) {
        [mode, step] = state.previousCrossWorkflow.split('.');
      }
      return { mode, step };
    },
    orgId: state => state.orgId,
    losLoanId: state => state.losLoanId,
    loanNumber: state => state.loanData?.loan?.loanNumber,
    losLoanHasQueuedRequest: state =>
      state.loanData?.lockRequests?.pendingLock?.isQueued,
    totalLockExtensionDays: state =>
      state.loanData?.lockRequests?.totalLockExtensionDays || 0,
    currentLockExceptions: (_, getters) =>
      getters.currentLock?.priceExceptions || [],
    persistedAdjustments: (state, getters) => {
      return [
        ...(getters.loanData?.adjustments || []),
        ...state.pendingPersistedAdjustments,
      ];
    },
    newRelockFee: (state, getters) => {
      const selectedRelockDays = getters.selectedRelockPeriod?.lock_period;
      const todaysDate = getDate(new Date().toISOString());

      return {
        kind: ADJUSTMENT_KIND.RELOCK_FEE,
        amount: getters.selectedRelockFee,
        description: `Relock Fee (${selectedRelockDays} days) - ${todaysDate}`,
      };
    },
    initialLock: state => state.loanData?.lockRequests?.initialLock,
    relockPolicy: (_, __, ___, rootGetters) =>
      rootGetters['lockDeskWorkflowPolicies/relockPolicy'],
    relockError: state => state.relockError,
    isRelockWithinMaxDays: (_, getters) =>
      getters.daysPastCancellationOrExpiration <=
      getters.relockPolicy.max_day_threshold,
    isPastRelockWorstCasePricingThreshold: (_, getters) =>
      getters.daysPastCancellationOrExpiration >
      getters.relockPolicy.relockWorstCasePricingThreshold,
    selectedRelockPeriod: (state, getters) => {
      const defaultLockPeriod = getters.relockPolicy?.fees?.find(
        ({ lock_period }) => lock_period === 30,
      );
      return (
        state.selectedRelockPeriod ||
        defaultLockPeriod ||
        getters.relockPolicy.fees[0]
      );
    },
    selectedRelockFee: (_, getters) => {
      const { fees } = getters?.selectedRelockPeriod || {};
      if (!fees) {
        return null;
      }

      return Number(fees[getters.totalRelocks] || fees[0]);
    },
    pricingVersions: state => state.pricingVersions,
    activePricingVersion: state =>
      state.pricingVersions.find(version => version.isPublished),
    totalRelocks: state => state.loanData?.lockRequests?.totalRelocks || 0,
    relockPolicyMessage: (_, getters) => {
      const {
        relockPolicy,
        totalRelocks,
        hasPreviousProductInCurrentVersion,
        currentLock,
      } = getters;
      if (!relockPolicy.isWorkflowEnabled || !relockPolicy.max_relocks) {
        return '';
      }

      try {
        const relocksRemaining = relockPolicy.max_relocks - totalRelocks;

        if (!hasPreviousProductInCurrentVersion) {
          return `A relock cannot be performed because the ${currentLock.productName} is not available in the active pricing configuration.`;
        }

        if (totalRelocks < relockPolicy.max_relocks) {
          return `This loan can be relocked ${relocksRemaining} more time${
            relocksRemaining !== 1 ? 's' : ''
          }.`;
        } else {
          return `This loan already contains ${totalRelocks} relock${
            totalRelocks !== 1 ? 's' : ''
          }, the max allowed.`;
        }
      } catch (e) {
        return 'The relock policy for this organization is invalid';
      }
    },
    loanLockRateStatus: state => state.loanData?.lockRateStatus,
    losLockRateStatus: state => state.losLoanData?.lockRateStatus,
    rawLosLockRateStatus: state => state.losLoanData?.losLockRateStatus,
    los: state => state.losLoanData?.los,
    isLockDeskOpen: getters => getters.lockDeskStatus === lockDeskStatus.opened,
    isBlockedByClosedLockDesk: (_, getters) => {
      const { NOT_LOCKED, EXPIRED, CANCELLED } = LOCK_RATE_STATUS;

      const { lock, relock, floatDown } = pricerModes;
      const blockedModes = [lock, relock, floatDown];

      /**
       * Block workflow if...
       * 1. lock desk is not open
       * 2. AND pricer is in lock, relock, or float-down mode
       * 3. AND loan has no lock, an expired lock, or a cancelled lock
       */

      return (
        !getters.isLockDeskOpen &&
        blockedModes.includes(getters.pricerMode) &&
        [NOT_LOCKED, EXPIRED, CANCELLED].includes(getters.loanLockRateStatus)
      );
    },
    cancellationOrExpirationDate: (_, getters) => {
      const { expirationDate, confirmedAt } = getters.currentLock || {};
      if (!expirationDate || !confirmedAt) {
        return null;
      }
      return getters.isLoanLockCancelled ? confirmedAt : expirationDate;
    },
    cancelledLock: state => state.loanData?.lockRequests?.cancelledLock,
    cancellationDate: (_, getters) => getters.cancelledLock?.confirmedAt,
    daysPastCancellationOrExpiration: (_, getters) => {
      const { expirationDate, confirmedAt } = getters.currentLock || {};
      if (!expirationDate || !confirmedAt) {
        return null;
      }
      const expiredAt = getters.isLoanLockCancelled
        ? confirmedAt
        : expirationDate;
      return getUTCDifferenceInDays(new Date(), parseISO(expiredAt));
    },
    // TODO: Move float down policy details into lockDeskWorkflowPolicies
    floatDownPolicy: (_, __, ___, rootGetters) =>
      rootGetters['lockDeskWorkflowPolicies/floatDownPolicy'],
    hasFloatDownEnabled: (_, getters) => {
      return getters.floatDownPolicy?.isWorkflowEnabled;
    },
    hasFloatDownAutoApprovalEnabled: (_, getters) =>
      getters.floatDownPolicy?.isAutoApprovalEnabled,
    canFloatDown: (_, getters) => {
      const { floatDownPolicy } = getters;
      // 1. must have float down enabled
      if (!floatDownPolicy.isWorkflowEnabled) {
        return false;
      }

      // 2. must meet minimum lock period requirement
      const { min_lock_period } = floatDownPolicy;
      if (getters.initialLock.lockPeriod < min_lock_period) {
        return false;
      }

      // 3. must have float down policy defined
      if (Object.keys(getters.floatDownPolicy).length === 0) {
        return false;
      }

      return true;
    },
    hasFloatDownPolicy: (_, getters) => {
      return Object.keys(getters.floatDownPolicy).length > 0;
    },
    maxFloatDowns: (_, getters) => getters.floatDownPolicy?.max_requests || 0,
    totalFloatDowns: state =>
      state.loanData?.lockRequests?.totalFloatDowns || 0,
    policyLimitMessage: state => state.policyLimitMessage,
    totalPriceExceptions: state =>
      state.loanData?.lockRequests?.totalPriceExceptions || 0,
    currentLockExceptionsValue: (_, getters) => {
      return getters.currentLockExceptions
        .map(exception => parseFloat(exception.value))
        .reduce((acc, curr) => acc + curr, 0);
    },
    isPricingInitializing: state => state.isPricingInitializing,
    getLockExpirationDate:
      (_, getters) =>
      (date, lockPeriod, holidays = [], lockRateAction) => {
        const daysToAdd = getters.lockExpirationSettings
          ?.lock_date_is_day_one_of_lock_period
          ? lockPeriod - 1
          : lockPeriod;

        const targetDate = addDays(date, { days: daysToAdd });

        let lock_exp_rule =
          getters.lockExpirationSettings?.lock_exp_date_scheduling_rule;
        if (lockRateAction === LOCK_RATE_ACTIONS.EXTENSION) {
          lock_exp_rule =
            getters.lockExpirationSettings
              ?.lock_extension_exp_date_scheduling_rule;
        } else if (lockRateAction === LOCK_RATE_ACTIONS.RELOCK) {
          lock_exp_rule =
            getters.lockExpirationSettings?.relock_exp_date_scheduling_rule;
        }

        if (lock_exp_rule === LOCK_EXP_DATE_SCHED_RULE.ON_SELECTED_DAY) {
          return targetDate;
        } else if (
          lock_exp_rule === LOCK_EXP_DATE_SCHED_RULE.NEXT_BUSINESS_DAY
        ) {
          return getNextBusinessDate(targetDate, holidays);
        } else {
          return getPrevBusinessDate(targetDate, holidays);
        }
      },
    currentAvailableProducts: state => state.currentAvailableProducts,
    hasPreviousProductInCurrentVersion: (_, getters) => {
      const { currentLock, currentAvailableProducts } = getters;
      return currentAvailableProducts.some(
        product => product.code === currentLock.productCode && product.isActive,
      );
    },
    currentRelockPeriod: state =>
      state.loanData?.lockRequests?.currentRelockPeriod,
    requestStatusMessage: (_, getters) => {
      const {
        hasInAppReviewalEnabled,
        losLoanHasQueuedRequest,
        isAutoApproved,
        isLoanLocked,
      } = getters;
      const autoApproveMessage =
        'will be automatically processed by your lock desk.';
      const pendingMessage = 'is awaiting approval from your lock desk.';
      const queuedMessage = 'is being sent to your lock desk.';
      const lockedMessage = 'has been processed by your lock desk.';

      if (isLoanLocked) return lockedMessage;
      if (isAutoApproved) return autoApproveMessage;
      if (hasInAppReviewalEnabled) return pendingMessage;
      if (losLoanHasQueuedRequest) return queuedMessage;
      return pendingMessage;
    },
    losUser: state => state.losUser,
    mortgageInsuranceData: state => state.mortgageInsuranceData,
    isLoanLockCancelled: (_, getters) => {
      return getters.loanLockRateStatus === LOCK_RATE_STATUS.CANCELLED;
    },
    isLoanManuallyLocked: (_, getters) => {
      // return true if a manual lock detected in encompass
      return (
        getters.loanLockRateStatus === LOCK_RATE_STATUS.LOCKED &&
        !getters.currentLock?.originalPriceRequest
      );
    },
    isLoadingDynamicPricingDetails: state =>
      state.isLoadingDynamicPricingDetails,
    pricingTemplate: state => state.pricingTemplate,
    errorMessage: state => state.errorMessage,
    customLoanTerms: state => state.customLoanTerms || [],
    mortgageCoachEnabled(state, getters, rootState, rootGetters) {
      return rootGetters['core/isSwitchActive'](MORTGAGE_COACH_INTEGRATION);
    },
    hasMortgageCoachIntegration(state, getters) {
      return (
        getters.mortgageCoachEnabled &&
        state.mortgageCoachAccount?.['hasAccount']
      );
    },
    hasValidMortgageCoachIntegration(state, getters) {
      return (
        getters.mortgageCoachEnabled &&
        state.mortgageCoachAccount?.['hasAccount'] &&
        state.mortgageCoachAccount?.['validAccount']
      );
    },
    backendTotalLoanAmountEnabled(state, getters, rootState, rootGetters) {
      return rootGetters['core/isSwitchActive'](
        BACKEND_TOTAL_LOAN_AMOUNT_CALCULATION,
      );
    },
    userScenarioConfig: state => state.userScenarioConfig,
    showCrossWorkflow(state, getters, rootState, rootGetters) {
      return (
        (rootGetters['core/orgPermissions']
          ?.has_price_exception_initial_lock_enabled &&
          getters.showExceptionWorkflow) ||
        rootGetters['core/orgPermissions']?.has_auto_expand_pe_by_default
      );
    },
    showExceptionWorkflow(state, getters, rootState, rootGetters) {
      const pePolicy =
        rootGetters['lockDeskWorkflowPolicies/priceExceptionPolicy'];
      if (
        rootGetters['core/orgPermissions']
          ?.has_price_exception_management_enabled
      ) {
        return (
          pePolicy?.sequences?.length &&
          pePolicy?.isWorkflowEnabled &&
          pePolicy?.sequences?.some(
            sequence =>
              sequence.requestor_group?.id ===
              rootGetters['members/currentUser']?.requestor_group?.id,
          )
        );
      } else {
        return pePolicy?.isWorkflowEnabled;
      }
    },
    isLoadingLock: state => state.isLoadingLock,
    documentsByProducts(state, getters, rootState, rootGetters) {
      return (
        state.documentsByConfigurations[
          rootGetters['pricing/selectedPricingVersionId'] ||
            rootGetters['pricingVersionDetails/configuration']?.id
        ] || {}
      );
    },
    documentsByConfigurations: state => state.documentsByConfigurations,
    priceExceptionValue: state => state.priceExceptionValue,
    priceExceptionPercent: state => state.priceExceptionPercent,
  },
  mutations: {
    [SET_PE_REQUEST_DATA](state, peRequestData) {
      state.peRequestData = peRequestData;
    },
    [SET_PRICING_SCENARIO_RESULT](state, pricingScenarioResult) {
      state.pricingScenarioResult = pricingScenarioResult;
    },
    [SET_USER_SCENARIO_CONFIG](state, userScenarioConfig) {
      state.userScenarioConfig = userScenarioConfig;
    },
    [SET_PRICE](state, newPrice) {
      state.selectedPrice = newPrice;
    },
    [SET_PREVIEW_PRICE](state, newPrice) {
      state.previewPrice = newPrice;
    },
    [SET_SELECTING_PRICE](state, selecting) {
      state.selectingPrice = selecting;
    },
    setLockDeskState(state, newState) {
      state.lockDeskStatus = newState;
    },
    SET_PRICER_MODE(state, pricerMode) {
      state.pricerMode = pricerMode;
    },
    [SET_PRODUCT_INFO](state, productInfo) {
      state.productInfo = productInfo;
    },
    SET_REQUESTED_REPRICE_LOCK(state, repriceLock) {
      state.requestedRepriceLock = repriceLock;
    },
    [SET_REQUESTING_LOCK](state, requestingLock) {
      state.requestingLock = !!requestingLock;
    },
    SET_AUDIENCES(state, options) {
      state.audiences = options;
    },
    SET_SELECTED_AUDIENCE_ID(state, audienceId) {
      state.selectedAudienceId = audienceId;
    },
    SET_SELECTED_PRICING_VERSION_ID(state, versionId) {
      state.selectedPricingVersionId = versionId;
    },
    [SET_LOAN_DATA](state, loanData) {
      state.loanData = loanData;
    },
    SET_WORKFLOW_PERMISSIONS(state, permissions) {
      state.workflowPermissions = permissions;
    },
    SET_WORKFLOW(state, workflow) {
      state.previousWorkflow = workflow != null ? state.workflow : null;
      if (workflow && workflow.mode && workflow.step) {
        state.workflow = `${workflow.mode}.${workflow.step}`;
      } else state.workflow = workflow;
    },
    SET_CROSS_WORKFLOW(state, crossWorkflow) {
      state.previousCrossWorkflow =
        crossWorkflow !== null ? state.crossWorkflow : null;
      if (crossWorkflow && crossWorkflow.mode && crossWorkflow.step) {
        state.crossWorkflow = `${crossWorkflow.mode}.${crossWorkflow.step}`;
      } else state.crossWorkflow = crossWorkflow;
    },
    SET_ORG_ID(state, orgId) {
      state.orgId = orgId;
    },
    SET_LOS_LOAN_ID(state, losLoanId) {
      state.losLoanId = losLoanId;
    },
    [SET_SELECTED_PRICING_VERSION](state, version) {
      state.selectedPricingVersion = version;
    },
    [SET_SELECTED_RELOCK_PERIOD](state, relockPeriod) {
      state.selectedRelockPeriod = relockPeriod;
    },
    SET_PRICING_VERSIONS(state, pricingVersions) {
      state.pricingVersions = pricingVersions;
    },
    [SET_FLOAT_DOWN_POLICY](state, policy) {
      state.floatDownPolicy = policy;
    },
    [SET_POLICY_LIMIT_MESSAGE](state, message) {
      state.policyLimitMessage = message;
    },
    [SET_IS_AUTO_APPROVED](state, isAutoApproved) {
      state.isAutoApproved = isAutoApproved;
    },
    [SET_IS_EXCEPTION_FIELDS_VALID](state, isExceptionFieldsValid) {
      state.isExceptionFieldsValid = isExceptionFieldsValid;
    },
    [SET_IS_PRICING_INITIALIZING](state, isPricingInitializing) {
      state.isPricingInitializing = isPricingInitializing;
    },
    [SET_CURRENT_AVAILABLE_PRODUCTS](state, products) {
      state.currentAvailableProducts = products;
    },
    [SET_CACHED_TEMPLATE](state, value) {
      state.cachedTemplate = value;
    },
    [SET_LOS_LOAN_DATA](state, losLoan) {
      state.losLoanData = Object.freeze(losLoan);
    },
    [SET_LOS_USER](state, losUser) {
      state.losUser = losUser;
    },
    [SET_PENDING_PERSISTED_ADJUSTMENTS](state, adjustments) {
      state.pendingPersistedAdjustments = adjustments;
    },
    [SET_EXTERNAL_VISIBILITY_OBJECT](state, newObj) {
      state.externalVisibilityObject = {
        ...state.externalVisibilityObject,
        ...newObj,
      };
    },
    [SET_CUSTOM_CREDIT_DATA](state, data) {
      state.customCreditData = data || {};
    },
    [SET_LOAN_STATUS_FOR_REFRESH](state, status) {
      state.loanStatusForRefresh = status;
    },
    [SET_MORTGAGE_INSURANCE_DATA](state, data) {
      state.mortgageInsuranceData = data;
    },
    [SET_LOADING_DYNAMIC_PRICING_DETAILS](
      state,
      isLoadingDynamicPricingDetails,
    ) {
      state.isLoadingDynamicPricingDetails = isLoadingDynamicPricingDetails;
    },
    [SET_PRICING_TEMPLATE](state, pricingTemplate) {
      state.pricingTemplate = pricingTemplate;
    },
    [SET_ERROR_MESSAGE](state, errorMessage) {
      state.errorMessage = errorMessage;
    },
    [SET_CUSTOM_LOAN_TERMS](state, customLoanTerms) {
      state.customLoanTerms = customLoanTerms;
    },
    [SET_INITIAL_LOCK_PRODUCT_INFO](state, productInfo) {
      state.initialLockProductInfo = productInfo;
    },
    [SET_SELECTED_PRICE_PRODUCT_INFO](state, productInfo) {
      state.selectedPriceProductInfo = productInfo;
    },
    [SET_PE_RULE_FIELDS_CACHE](state, peRuleFieldsCache) {
      state.peRuleFieldsCache = peRuleFieldsCache;
    },
    [SET_MORTGAGE_COACH_ACCOUNT](state, account) {
      state.mortgageCoachAccount = account;
    },
    [SET_SELECTED_PRODUCT_PRICES](state, prices) {
      state.selectedProductPrices = prices;
    },
    [SET_SELL_SIDE_LOCKED_AT](state, newDateTime) {
      state.sellSideLockedAt = newDateTime;
    },
    [SET_SELL_SIDE_LOCK_PERIOD](state, newLockPeriod) {
      state.sellSideLockPeriod = newLockPeriod;
    },
    [SET_SELL_SIDE_EXPIRES_AT](state, newDate) {
      state.sellSideExpiresAt = newDate;
    },
    [SET_SORTED_PRODUCTS](state, sortedProducts) {
      state.sortedProducts = sortedProducts;
    },
    ['SET_PRICE_AFTER_EXCEPTION'](state, price) {
      state.priceAfterException = price;
    },
    [SET_IS_LOADING_LOCK](state, isLoadingLock) {
      state.isLoadingLock = isLoadingLock;
    },
    [SET_DOCUMENT_BY_CONFIGURATION](
      state,
      { configurationId, documentsByProducts },
    ) {
      state.documentsByConfigurations = {
        ...state.documentsByConfigurations,
        [configurationId]: documentsByProducts,
      };
    },
    [SET_ALL_IN_PRICE_INVALID](state, isInvalid) {
      state.isAllInPriceInvalid = isInvalid;
    },
    [SET_PRICE_EXCEPTION](state, { value, percent }) {
      state.priceExceptionValue = value;
      state.priceExceptionPercent = percent;
    },
  },
  actions: {
    setIsAllInPriceInvalid({ commit }, isInValid) {
      commit(SET_ALL_IN_PRICE_INVALID, isInValid);
    },
    async getRuleFields({ getters, rootGetters, dispatch, state, commit }) {
      const configId =
        getters.selectedPricingVersionId ||
        rootGetters['pricingVersionDetails/configuration']?.id;
      if (!configId) {
        commit(SET_PE_RULE_FIELDS_CACHE, null);
        return;
      }

      if (
        state.peRuleFieldsCache?.configId &&
        configId == state.peRuleFieldsCache?.configId
      ) {
        return;
      }

      commit(SET_PE_RULE_FIELDS_CACHE, null);
      const audiences = await getAudiences(configId);
      const params = await getEntities(configId, 'custom-parameters');
      const ruleFields = await getPERuleFields(configId, params, audiences);

      const cache = {
        configId: configId,
        customParams: params,
        audiences: audiences,
        peRuleFields: ruleFields,
      };

      commit(SET_PE_RULE_FIELDS_CACHE, cache);
      await dispatch('ruleDetails/setRuleFields', ruleFields, { root: true });
    },
    setProductInfo({ commit }, productInfo) {
      commit(SET_PRODUCT_INFO, productInfo);
    },
    async fetchExternalVisibilityObject({ commit }, includeInvestors = null) {
      const params = {};
      if (includeInvestors?.length > 0) {
        params.includeInvestors = includeInvestors;
      }

      const url = api.constructUrl('/pe/api/external-rule-visibility/', params);
      const result = await api.get(url);
      commit(SET_EXTERNAL_VISIBILITY_OBJECT, result);
    },
    /**
     * Returns list of rule IDs from externalVisibilityObject for provided orgs
     * plus current org. If provided IDs are not in the store, makes API request to
     * retrieve them
     *
     * @param investorIds {string[]} list of org IDs to get rule visibility for
     * @return {Promise<string[]>}
     */
    async getExternalVisibilityForInvestors(
      { state, dispatch, rootGetters },
      investorIds = [],
    ) {
      const currentOrgId =
        rootGetters['organizations/currentOrganizationId'].toString();
      const orgsToGet = [...investorIds];
      if (!orgsToGet.includes(currentOrgId)) {
        orgsToGet.push(currentOrgId);
      }

      const notFetchedBefore = orgsToGet.filter(
        x => !Object.keys(state.externalVisibilityObject).includes(x),
      );

      if (notFetchedBefore.length > 0) {
        await dispatch('fetchExternalVisibilityObject', notFetchedBefore);
      }

      let result = [];
      orgsToGet.forEach(investorId => {
        if (state.externalVisibilityObject?.[investorId]) {
          result = result.concat(state.externalVisibilityObject?.[investorId]);
        }
      });

      return result;
    },
    selectPrice({ commit, state }, newPrice) {
      const { audiences, selectedAudienceId } = state;
      const audience = audiences.find(
        aud =>
          aud.id === selectedAudienceId ||
          aud.name === selectedAudienceId ||
          aud.id === newPrice.pe_request_audienceId ||
          aud.name === newPrice.pe_request_audienceId,
      );
      newPrice.changesetId =
        newPrice.changesetId || state.selectedPricingVersionId;
      newPrice.channel = audience?.name;
      newPrice.loanNumber = state.loanData?.loan?.loanNumber;
      commit(SET_PRICE, newPrice);
    },
    previewPrice({ commit }, newPrice) {
      commit(SET_PREVIEW_PRICE, newPrice);
    },
    setAvailableLenderCreditData({ getters, commit, state }) {
      const netPrice = state.selectedPrice?.netPrice
        ? state.selectedPrice?.netPrice
        : getters.pendingLock?.netPrice;

      const availableLenderCreditPercent = netPrice > 100 ? netPrice - 100 : 0;

      const data = {
        availableLenderCreditPercent: availableLenderCreditPercent,
        availableLenderCreditAmount:
          (state.loanData?.loan?.amount * availableLenderCreditPercent) / 100,
      };
      commit(SET_CUSTOM_CREDIT_DATA, { ...state.customCreditData, ...data });
    },
    setTotalEstPrepaidData({ commit, state }) {
      const data = {
        applyMaximumLenderCredit:
          state.loanData?.customCredit?.applyMaximumLenderCredit,
        totalEstPrepaidAndClosingCostAmount:
          state.loanData?.customCredit?.totalEstPrepaidAndClosingCostAmount,
        totalEstPrepaidAndClosingCostPercentage:
          state.loanData?.customCredit?.totalEstPrepaidAndClosingCostPercentage,
      };
      commit(SET_CUSTOM_CREDIT_DATA, { ...state.customCreditData, ...data });
    },
    setCustomCreditData({ commit, state }, data) {
      commit(SET_CUSTOM_CREDIT_DATA, { ...state.customCreditData, ...data });
    },
    clearCustomCreditData({ commit }) {
      commit(SET_CUSTOM_CREDIT_DATA, null);
    },
    cancelPrice({ commit }) {
      commit(SET_PRICE, null);
    },
    clearPERuleFieldsCache({ commit }) {
      commit(SET_PE_RULE_FIELDS_CACHE, null);
    },
    async setWorkflowFromLockRateStatus(
      { dispatch, commit, state },
      orgPermissions,
    ) {
      const data = { orgId: state.orgId, loanId: state.losLoanId };
      const freshLoanData = await dispatch('getLosLoanData', data);
      const { workflow, pricerMode, crossWorkflow } =
        getModeAndWorkflowFromLoanData(freshLoanData, orgPermissions);
      state.loanData.lockRateStatus = freshLoanData.lockRateStatus;
      commit('SET_WORKFLOW', workflow);
      commit('SET_CROSS_WORKFLOW', crossWorkflow);
      commit('SET_PRICER_MODE', pricerMode);
    },
    async openLockDesk({ commit }) {
      await api.patch('/pe/api/pricer/lockdesk/', { op: 'open' });
      commit('setLockDeskState', lockDeskStatus.opened);
    },
    async suspendPricing({ commit }) {
      await api.patch('/pe/api/pricer/lockdesk/', { op: 'suspend' });
      commit('setLockDeskState', lockDeskStatus.suspended);
    },
    async getLockDeskState({ commit }) {
      const result = await api.get('/pe/api/pricer/lockdesk/');
      commit('setLockDeskState', lockDeskStatus[result.state]);
    },
    setPricerMode({ commit }, mode) {
      commit('SET_PRICER_MODE', mode);
    },
    setRequestedRepriceLock({ commit, state }, repriceLock) {
      const { amount } = state.loanData.loan;
      const { netPrice } = repriceLock;
      repriceLock.creditCost = calculateCreditCost(netPrice, amount);
      commit('SET_REQUESTED_REPRICE_LOCK', repriceLock);
    },
    async fetchAudiencesForVersion({ commit, state }, version) {
      const audiences = await getAudiences(version);
      commit('SET_AUDIENCES', audiences);
      if (!state.selectedAudienceId) {
        commit('SET_SELECTED_AUDIENCE_ID', audiences[0].id);
      }
    },
    setSelectedAudienceId({ commit }, audienceId) {
      commit('SET_SELECTED_AUDIENCE_ID', audienceId);
    },
    setSelectedPricingVersionID({ commit }, versionId) {
      commit('SET_SELECTED_PRICING_VERSION_ID', versionId);
    },
    async getLosLoanData({ commit }, loanData) {
      console.log('getLosLoanData', loanData.loanId, loanData.orgId);
      const response = await api.get(
        `/pe/api/pricer/loans/${loanData.loanId}?orgId=${loanData.orgId}`,
      );
      // Set the original response data to validate against before lock request.
      if (loanData.commit !== false) {
        commit(SET_LOS_LOAN_DATA, cloneDeep(response.data));
      }
      console.log('getLosLoanData response', cloneDeep(response.data));
      return response.data;
    },
    async compareLosLoanData({ dispatch, state }, loanData) {
      if (!state.losLoanData) {
        return false;
      }
      loanData.commit = false;
      const freshLoanData = await dispatch('getLosLoanData', loanData);
      return isEqual(freshLoanData, state.losLoanData);
    },
    setLoanData({ commit }, loanData) {
      for (const request in loanData.lockRequests) {
        const price = loanData?.lockRequests[request]?.netPrice;
        const productCode = loanData?.lockRequests[request]?.productCode;
        const product = loanData?.lockRequests[
          request
        ]?.originalPriceRequest?.results?.find(
          result => result?.code === productCode,
        );

        if (price) {
          loanData.lockRequests[request].creditCost = getCreditCost(
            product?.loanType,
            price,
            loanData?.lockRequests[request]?.originalPriceRequest?.loan,
          );
        }
      }
      commit(SET_LOAN_DATA, loanData);
      if (loanData.customCredit) {
        commit(SET_CUSTOM_CREDIT_DATA, loanData.customCredit);
      }
    },
    clearLoanData({ commit }) {
      commit(SET_LOAN_DATA, null);
      commit(SET_CUSTOM_CREDIT_DATA, {});
    },
    async getWorkflowPermissions({ commit, state }) {
      if (state.workflowPermissions) {
        return state.workflowPermissions;
      }
      const permissions = await api.get('/pe/api/workflow-permissions/');
      commit('SET_WORKFLOW_PERMISSIONS', permissions);
      return permissions;
    },
    setWorkflow({ commit }, workflow) {
      commit('SET_WORKFLOW', workflow);
    },
    setCrossWorkflow({ commit }, crossWorkflow) {
      commit('SET_CROSS_WORKFLOW', crossWorkflow);
    },
    setOrgId({ commit }, orgId) {
      commit('SET_ORG_ID', orgId);
    },
    setLosLoanId({ commit, dispatch, state }, losLoanId) {
      const prevLosLoanId = state.losLoanId;
      commit('SET_LOS_LOAN_ID', losLoanId);
      // Piggyback off this action to empty any saved MI data if loan id changes
      if (
        prevLosLoanId !== state.losLoanId &&
        Object.keys(state.mortgageInsuranceData).length
      ) {
        dispatch('setMortgageInsuranceData', {});
      }
    },
    setSelectedPricingVersion({ commit }, version) {
      commit(SET_SELECTED_PRICING_VERSION, version);
    },
    setSelectedRelockPeriod({ commit }, relockPeriod) {
      commit('SET_SELECTED_RELOCK_PERIOD', relockPeriod);
    },
    setPricingVersions({ commit }, pricingVersions) {
      commit('SET_PRICING_VERSIONS', pricingVersions);
    },
    setFloatDownPolicy({ commit }, policy) {
      commit(SET_FLOAT_DOWN_POLICY, policy);
    },
    setPolicyLimitMessage({ commit }, message) {
      commit(SET_POLICY_LIMIT_MESSAGE, message);
    },
    setIsPricingInitializing({ commit }, isPricingInitializing) {
      commit(SET_IS_PRICING_INITIALIZING, isPricingInitializing);
    },
    setCurrentAvailableProducts({ commit }, products) {
      commit(SET_CURRENT_AVAILABLE_PRODUCTS, products);
    },
    setLosUser({ commit }, losUser) {
      commit(SET_LOS_USER, losUser);
    },
    setCustomParameters({ state }, customParameters) {
      // go over all the real parameters that are known, and match them up with any the scenario
      // is trying to set up. The available custom parameters can change based on a couple of
      // properties, and this ensures none are getting added that don't exist anymore
      Object.values(customParameters).forEach(realCustomParameter => {
        const scenarioCustomParameter =
          state.pricingTemplate['customParameters'][realCustomParameter.name];
        if (scenarioCustomParameter) {
          realCustomParameter.value = scenarioCustomParameter.value;
          realCustomParameter.visible = scenarioCustomParameter.visible;
        }
      });
    },
    setUserScenarioConfig({ commit }, userScenarioConfig) {
      commit(SET_USER_SCENARIO_CONFIG, userScenarioConfig);
    },
    async loadDefaultScenario(
      { dispatch, rootGetters },
      customScenarioParameters,
    ) {
      const orgId = rootGetters['organizations/organization'].id;
      const userScenarioConfig = await getPricingScenarioUserConfig(orgId);
      if (userScenarioConfig) {
        dispatch('setUserScenarioConfig', userScenarioConfig);
      }

      if (!(userScenarioConfig && userScenarioConfig.default_scenario)) {
        return false;
      }

      let url = `/api/v1/organizations/${orgId}/custom_pricing_scenarios/`;
      const query_by_scenario_id = {
        scenario_id: userScenarioConfig.default_scenario,
      };
      url = api.constructUrl(url, query_by_scenario_id);
      const custom_scenarios = await api.get(url);

      // given that the scenario id is a single instance filter we can assume the first element is the default_scenario
      if (custom_scenarios.results.length === 1) {
        dispatch('mergeScenarioWithTemplate', {
          scenario: custom_scenarios.results[0].pe3_custom_request,
          customParameters: customScenarioParameters,
        });
        return true;
      }
      return false;
    },
    mergeScenarioWithTemplate(
      { dispatch, state },
      { scenario, customParameters },
    ) {
      // Merge saved scenario on top of existing pricer UI template.

      //exclude version from saved scenario and use template setting
      if (scenario?.options?.version) {
        delete scenario['options']['version'];
      }
      const mergedTemplate = merge(state.pricingTemplate, scenario);
      dispatch('setPricingTemplate', mergedTemplate);
      dispatch('setCustomParameters', customParameters);
    },
    loadCachedScenario({ dispatch, commit, state }, customScenarioParameters) {
      if (!state.cachedTemplate) {
        return false;
      }

      dispatch('setPricingTemplate', state.cachedTemplate);
      dispatch('setCustomParameters', customScenarioParameters);
      commit(SET_CACHED_TEMPLATE, null);
      return true;
    },
    async pushQueuedRequest(
      { commit, state },
      { lockRequest, lockRateStatus },
    ) {
      lockRequest.isQueued = true;
      const loanData = JSON.parse(JSON.stringify(state.loanData));
      loanData.lockRateStatus = lockRateStatus;
      loanData.lockRequests.pendingLock = lockRequest;
      commit(SET_LOAN_DATA, loanData);
    },
    removeQueuedRequest({ commit, state }) {
      const loanData = JSON.parse(JSON.stringify(state.loanData));
      const isCrossWorkflowPending =
        loanData.lockRequests?.pendingLock?.childLockRequests?.length;
      if (isCrossWorkflowPending || state.workflow === null) {
        loanData.lockRateStatus = LOCK_RATE_STATUS.NOT_LOCKED;
      }
      delete loanData.lockRequests.pendingLock;
      commit(SET_LOAN_DATA, loanData);
    },
    setPendingPersistedAdjustments({ commit }, adjustments) {
      commit(SET_PENDING_PERSISTED_ADJUSTMENTS, [...adjustments]);
    },
    setLoanStatusForRefresh({ commit }, status) {
      commit(SET_LOAN_STATUS_FOR_REFRESH, status);
    },
    setMortgageInsuranceData({ commit }, data) {
      // This data need not be reactive
      commit(SET_MORTGAGE_INSURANCE_DATA, Object.freeze(data));
    },
    setLoadingDynamicPricingDetails(
      { commit },
      isLoadingDynamicPricingDetails,
    ) {
      commit(
        SET_LOADING_DYNAMIC_PRICING_DETAILS,
        isLoadingDynamicPricingDetails,
      );
    },
    setPricingTemplate({ commit }, pricingTemplate) {
      commit(SET_PRICING_TEMPLATE, pricingTemplate);
    },
    setErrorMessage({ commit }, errorMessage) {
      commit(SET_ERROR_MESSAGE, errorMessage);
    },
    async getCustomLoanTerms({ commit }, configurationId) {
      const data = await api.get(
        `/pe/api/configurations/${configurationId}/custom_loan_terms`,
      );
      commit(SET_CUSTOM_LOAN_TERMS, data.customLoanTerms);
    },
    async getMortgageCoachAccount({ state, commit }) {
      if (Object.keys(state.mortgageCoachAccount).length) {
        return state.mortgageCoachAccount;
      }
      const account = await hasValidExternalAccount('Mortgage Coach');
      commit(SET_MORTGAGE_COACH_ACCOUNT, account);
      return account;
    },
    setIsAutoApproved({ commit }, isAutoApproved) {
      commit(SET_IS_AUTO_APPROVED, isAutoApproved);
    },
    setIsExceptionFieldsValid({ commit }, isExceptionFieldsValid) {
      commit(SET_IS_EXCEPTION_FIELDS_VALID, isExceptionFieldsValid);
    },
    setSortedProducts({ commit }, sortedProducts) {
      commit(SET_SORTED_PRODUCTS, sortedProducts);
    },
    async submitInitialLockRequest(
      { commit, dispatch, getters, rootGetters },
      { ignorePolicies, isBundled = false },
    ) {
      // sanitize the input
      ignorePolicies = ignorePolicies === true;
      const lockPolicy = rootGetters['lockDeskWorkflowPolicies/lockPolicy'];
      dispatch(
        'setIsAutoApproved',
        lockPolicy.isAutoApprovalEnabled || ignorePolicies,
      );
      try {
        commit(SET_IS_LOADING_LOCK, true);

        const product = await getProduct(
          getters.selectedPrice.changesetId,
          getters.selectedPrice.productId,
        );
        dispatch('setProductInfo', product);

        const loanAmount = loanTotalByType(
          product.loanType,
          getters.loanData,
          getters.fullWorkflow,
          getters.pricingTemplate,
        );

        const { pe_request_id, productId, rate, lockPeriod, apor, aporDate } =
          getters.selectedPrice;

        const requestBody = {
          pe_request_id,
          productId,
          rate,
          lockPeriod,
          apor,
          aporDate,
          totalLoanAmount: loanAmount,
          ignorePolicies,
          isBundled,
        };

        const response = await postInitialLockRequest(
          getters.losLoanId,
          requestBody,
        );

        if (response) {
          const { lockRequest } = response;

          const queuedLockDetails = {
            ...getters.selectedPrice,
            id: lockRequest.id,
            requestedAt: new Date().toISOString(),
          };
          if (!isBundled) {
            queuedLockDetails.customCredit =
              await addAllInPriceToLosCustomCredit(
                getters.losLoanId,
                false,
                response.lockRequest.id,
                rootGetters['core/orgPermissions'],
                getters.customCreditData,
              );
          }

          if (isBundled) {
            queuedLockDetails.lockRequestStatus =
              LOCK_REQUEST_STATUS.BUNDLE_PENDING;
          }
          dispatch('pushQueuedRequest', {
            lockRequest: queuedLockDetails,
            lockRateStatus: LOCK_RATE_STATUS.LOCK_PENDING,
          });
          if (!isBundled) {
            dispatch('setWorkflow', 'LOCK.LOCK_PENDING');
          }
          return true;
        } else {
          dispatch(
            'setErrorMessage',
            'A lock request is not permitted on this product at this time.',
          );
          return false;
        }
      } catch (error) {
        if (error.response?.data?.errorMessage) {
          dispatch('setErrorMessage', error.response.data.errorMessage);
        }
        onErrorHandler(
          error,
          'pe-pricer-ui-lock-request',
          [403],
          false,
          'Lock Request submission failed. Please contact support.',
        );
        return false;
      } finally {
        commit(SET_IS_LOADING_LOCK, false);
      }
    },
    async cancelCrossWorkflowRequest(
      { dispatch, rootGetters },
      { pendingLockRequestId, setWorkflow = true },
    ) {
      try {
        const deleted = await deleteLockRequest(pendingLockRequestId);
        if (deleted.id && setWorkflow) {
          await dispatch(
            'setWorkflowFromLockRateStatus',
            rootGetters['core/orgPermissions'],
          );
          dispatch('removeQueuedRequest');
        }
      } catch (err) {
        onErrorHandler(err, 'pe-pricer-ui-delete-bundle-pending-lock');
      }
    },
    setIsLoadingLock({ commit }, isLoadingLock) {
      commit(SET_IS_LOADING_LOCK, isLoadingLock);
    },
    async fetchGuidelineDocuments(
      { commit, getters },
      { orgId, configurationId },
    ) {
      if (
        !configurationId ||
        configurationId in getters.documentsByConfigurations
      ) {
        return;
      }
      const documents =
        (await getProductGuidelineDocuments(
          orgId,
          configurationId,
          null,
          null,
          true,
        )) || [];
      const documentsByProducts = documents.reduce(
        (docsByProducts, guidelineDocument) => {
          guidelineDocument.product_ids?.forEach(productId => {
            if (!docsByProducts[productId]) {
              docsByProducts[productId] = {
                [guidelineDocument.id]: guidelineDocument,
              };
            } else {
              docsByProducts[productId][guidelineDocument.id] =
                guidelineDocument;
            }
          });
          return docsByProducts;
        },
        {},
      );
      commit(SET_DOCUMENT_BY_CONFIGURATION, {
        configurationId,
        documentsByProducts,
      });
    },
    setAllInPriceFields({ commit, state }, allInPriceFields) {
      commit(SET_CUSTOM_CREDIT_DATA, {
        ...state.customCreditData,
        ...allInPriceFields,
      });
    },
  },
};
