<template>
  <div v-if="defaultOrg" class="user-orgs-accordion accordion">
    <div class="btn-container">
      <side-nav-tooltip :content="defaultOrg.name">
        <button
          class="btn btn-short default-org-btn text-accent"
          :class="{ 'no-user-orgs': !isToggleEnabled }"
          :tabindex="isToggleEnabled ? 0 : -1"
          :title="defaultOrg.name"
          @click="isToggleEnabled && toggleSwitcher()"
        >
          <span class="full-link mr-2">
            {{ defaultOrg.name }} ({{ defaultOrg.ticker }})
            <span v-if="!defaultOrg.is_active">(Inactive)</span>
          </span>
          <span class="ticker-link mr-2">
            {{ defaultOrg.ticker }}
          </span>
          <px-icon
            v-if="isToggleEnabled && orgToggle"
            class="icon"
            icon="chevron-up"
          ></px-icon>
          <px-icon
            v-else-if="isToggleEnabled && !orgToggle"
            class="icon"
            icon="chevron-down"
          ></px-icon>
        </button>
      </side-nav-tooltip>
    </div>
    <b-collapse v-model="orgToggle">
      <div class="text-muted">Switch to:</div>
      <ul>
        <li v-for="org in shownOrgs" :key="org.id">
          <side-nav-tooltip :content="org.name">
            <button
              class="p-0 mb-2 text-left btn btn-short text-accent"
              :title="org.name"
              @click.prevent="switchOrgs(org.id)"
            >
              <span class="full-link">{{ org.name }} ({{ org.ticker }})</span>
              <span class="ticker-link">{{ org.ticker }}</span>
            </button>
          </side-nav-tooltip>
        </li>
      </ul>
      <div v-if="hasOrgsNotShown">
        <side-nav-tooltip content="View all">
          <a
            class="text-accent more-user-orgs-link"
            href="/accounts/membership/"
            title="View all"
          >
            <span class="full-link">
              <px-icon class="mr-2 icon-dropdown" icon="maximize"></px-icon>
              <em>View all</em>
            </span>
            <span class="ticker-link">
              <px-icon class="icon-dropdown" icon="maximize"></px-icon>
            </span>
          </a>
        </side-nav-tooltip>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SideNavTooltip from './SideNavTooltip.vue';
import PxIcon from '../PxIcon.vue';

export default {
  components: { SideNavTooltip, PxIcon },
  props: {
    visibleOrgs: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      orgToggle: false,
    };
  },
  computed: {
    ...mapGetters({
      userOrgs: 'core/userOrgs',
    }),
    defaultOrg() {
      return this.userOrgs?.orgs?.find(org => org.is_default);
    },
    availableOrgs() {
      return this.userOrgs?.orgs
        ?.filter(org => !org.is_default)
        ?.sort((a, b) => a.name.localeCompare(b.name));
    },
    shownOrgs() {
      return this.availableOrgs.slice(0, this.visibleOrgs);
    },
    hasOrgsNotShown() {
      return this.availableOrgs.length > this.visibleOrgs;
    },
    isToggleEnabled() {
      return this.availableOrgs.length > 0;
    },
  },
  methods: {
    ...mapActions({
      switchOrganization: 'organizations/switchOrganization',
    }),
    async switchOrgs(orgId) {
      await this.switchOrganization(orgId);
      // navigate them to the index page after switch
      if (this.$route.path !== '/') {
        await this.$router.push('/');
      } else {
        // in order to update apm logging, refresh the page to re init apm logging with the current org.
        location.reload();
      }
    },
    toggleSwitcher() {
      this.orgToggle = !this.orgToggle;
    },
  },
};
</script>

<style scoped lang="scss">
.no-user-orgs {
  cursor: default !important;
}
</style>
